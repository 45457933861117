import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlConst } from '@app/core/constants/url.const';
import { HttpClient } from '@angular/common/http';
import { URLService } from '@app/core/services/url.service';

@Injectable()
export class TermsProvider {
  constructor(private http: HttpClient, private urlService: URLService) {
    //
  }

  getTerms(): Observable<any> {
    return this.http.get(this.urlService.generate(UrlConst.TERMS));
  }

  setTermsAgree(): Observable<any> {
    return this.http.post(this.urlService.generate(UrlConst.TERMS_AGREE), {});
  }
}
