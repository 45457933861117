import { dashboardNgrxModuleName } from '@app/dashboard/ngrx/dashboard-ngrx.const';
import { dashboardFiltersReducer } from '@app/dashboard/ngrx/reducers/dashboard-filters.reducer';
import { dashboardReducer } from '@app/dashboard/ngrx/reducers/dashboard.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface DashboardState {
  filters: any;
  data: any;
}

export const reducers: ActionReducerMap<DashboardState> = {
  filters: dashboardFiltersReducer,
  data: dashboardReducer,
};

export const getDashboardState = createFeatureSelector<DashboardState>(dashboardNgrxModuleName);
