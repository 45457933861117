import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'static-alert',
  templateUrl: './static-alert.component.html',
  styleUrls: ['./static-alert.component.scss'],
})
export class StaticAlertComponent {
  @Input() type = 'success';
  @Input() title: string;
  @Input() message: string;
  @Input() redirect: string;
  @Input() redirectName: string;
  @Input() params: any;

  constructor(private router: Router) {
  }

  private redirectTo(redirect) {
    return this.router.navigateByUrl(redirect);
  }
}
