import { Injectable, Inject } from '@angular/core';
import { APP_FORM_VALIDATION, IAppFormValidation } from './validation.compose';
import { IValidationConfig } from './abstract-validation.service';

// @todo: extends BaseValidationService
@Injectable({ providedIn: 'root' })
export class ValidationService implements IValidationConfig {
  constructor(@Inject(APP_FORM_VALIDATION) public formValidation: IAppFormValidation) {}

  getConfig() {
    return this.formValidation;
  }

  getParams(control) {
    const name = typeof control === 'string' ? control : control.name;
    const v = this.getValidation(name);
    if (v) {
      return v.params;
    }
  }

  getRules(control, data = {}) {
    const name = typeof control === 'string' ? control : control.name;
    const v: any = this.getValidation(name);

    if (v) {
      if (typeof v === 'function') {
        return v(data).rules;
      }

      return v.rules;
    }
  }

  getValidation(controlName) {
    return this.getConfig()[controlName];
  }
}
