import { Action } from '@ngrx/store';

export interface IActions {
  [p: string]: any;
}

export const setAction = (moduleName, actions) => {
  const module = moduleName.toUpperCase();
  const action = actions.join('');
  const type = actions.join('_').toUpperCase();
  const type_str = actions.join(' ').toUpperCase();
  const types = {
    [type]: `[${module}][${type_str}] Started`,
    [`${type}_COMPLETED`]: `[${module}][${type_str}] Completed`,
    [`${type}_FAILED`]: `[${module}][${type_str}] Failed`,
    [`${type}_RESET`]: `[${module}][${type_str}] Reset`,
  };

  return {
    ...types,
    [`${action}Action`]: class implements Action {
      readonly type = types[type];

      constructor(public payload?: any) {}
    },

    [`${action}CompletedAction`]: class implements Action {
      readonly type = types[`${type}_COMPLETED`];

      constructor(public payload?: any) {}
    },

    [`${action}FailedAction`]: class implements Action {
      readonly type = types[`${type}_FAILED`];

      constructor(public payload?: any) {}
    },

    [`${action}ResetAction`]: class implements Action {
      readonly type = types[`${type}_RESET`];

      constructor(public payload?: any) {}
    },
  };
};
