import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { accountActions } from '@app/account/ngrx/actions';

export const defaultState: any = {
  loading: false,
  user: null,
  error: null,
};

export function accountReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case accountActions.getAccount.GET_ACCOUNT: {
      return update(state, {
        user: { $set: null },
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case accountActions.getAccount.GET_ACCOUNT_COMPLETED: {
      const { user } = (action as any).payload;
      return update(state, {
        user: { $set: user },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case accountActions.getAccount.GET_ACCOUNT_FAILED: {
      return update(state, {
        user: { $set: null },
        loading: { $set: false },
        error: { $set: (action as any).payload },
      });
    }

    default:
      return state;
  }
}
