import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastNgrxService } from '@app/shared/modules/toasts/services/toast-ngrx.service';

@Injectable()
export class ToastsEffect {
  constructor(private actions$: Actions, private toastNgrxService: ToastNgrxService) {
    //
  }

  postNotify$ = createEffect(
    () => {
      return this.actions$.pipe(switchMap(payload => this.processNotification(payload)));
    },
    { dispatch: false },
  );

  processNotification(payload) {
    this.toastNgrxService.process(payload.type);

    return of(payload);
  }
}
