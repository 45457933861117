<h1>{{ 'pages.dashboard.title' | translate }}</h1>
<mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="'400px'" gutterSize="20px">
    <form [formGroup]="formFilters" *ngIf="options$ | async as options">

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                <custom-datepicker
                        formControlName="date"
                        [options]="{isToday: isToday$ | async}"
                        (onSelectData)="dispatchFilterChange(types.ALL_SALES)"
                ></custom-datepicker>
            </mat-grid-tile-header>
            <summary-info [summary]="(dashboardData$ | async)?.summary"></summary-info>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                <custom-select
                        formControlName="hourly"
                        [optionsItem]="options?.hourlyOptions"
                        (onSelectData)="dispatchFilterChange(types.HOURLY_AMOUNT)"
                ></custom-select>
                <custom-select
                        formControlName="hourly_range"
                        [optionsItem]="options?.hourlyRange"
                        (onSelectData)="dispatchFilterChange(types.HOURLY_AMOUNT)"
                ></custom-select>
            </mat-grid-tile-header>
            <line-graph [sets]="hourlyGraphSets$ | async"></line-graph>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                {{'pages.dashboard.graphs.department_sales' | translate | uppercase}}
            </mat-grid-tile-header>
            <doughnut-graph [sets]="departmentGraphSets$ | async"></doughnut-graph>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                <custom-select
                        formControlName="gross"
                        [optionsItem]="options?.grossOptions"
                        (onSelectData)="dispatchFilterChange(types.GROSS_SALES)"
                ></custom-select>
            </mat-grid-tile-header>
            <bar-graph [sets]="grossGraphSets$ | async"></bar-graph>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                <custom-select
                        formControlName="net"
                        [optionsItem]="options?.netSalesOptions"
                        (onSelectData)="dispatchFilterChange(types.NET_SALES)"
                ></custom-select>
            </mat-grid-tile-header>
            <bar-graph [sets]="netGraphSets$ | async"></bar-graph>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-grid-tile-header>
                <mat-icon>apps</mat-icon>
                <custom-select
                        formControlName="todate"
                        [optionsItem]="options?.toDateOptions"
                        (onSelectData)="dispatchFilterChange(types.TODATE_SALES)"
                ></custom-select>
            </mat-grid-tile-header>
            <line-graph [sets]="toDateGraphSets$ | async"></line-graph>
        </mat-grid-tile>

    </form>
</mat-grid-list>

<div class="refresh-section">
    <button mat-raised-button class="btn btn-primary" (click)="refreshData()">{{'pages.dashboard.refresh-data' | translate}}</button>
    <span>{{'pages.dashboard.last-updated' | translate}}: {{ (lastUpdated$ | async) || ('pages.dashboard.not-found' | translate) }}</span>
</div>
