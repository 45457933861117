import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { termsNgrxModuleName } from '@app/shared/modules/terms/ngrx/terms-ngrx.const';
import { termsReducer } from '@app/shared/modules/terms/ngrx/reducers/terms.reducer';

export interface TermsState {
  terms: any;
}

export const reducers: ActionReducerMap<TermsState> = {
  terms: termsReducer,
};

export const getTermsState = createFeatureSelector<TermsState>(termsNgrxModuleName);
