import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { Router } from '@angular/router';
import { appRoutingNames } from '@app/app-routing-names';
import { AccountNgrxService } from '@app/account/services/account-ngrx.service';

@Component({
  templateUrl: './account-finalize.modal.component.html',
  styleUrls: ['./account-finalize.modal.component.scss'],
})
export class AccountFinalizeModalComponent {
  processing = false;
  accountForm = new FormGroup({
    company_name: new FormControl('', [validationCompose.company.rules]),
  });

  constructor(
    public dialogRef: MatDialogRef<AccountFinalizeModalComponent>,
    private accountNgrxService: AccountNgrxService,
    private router: Router,
  ) {
    //
  }

  onSubmit() {
    if (!this.accountForm.valid) {
      return;
    }

    this.processing = true;
    this.accountNgrxService.dispatchSetSchema(this.accountForm.value);
    this.dialogRef.close();
    return this.router.navigateByUrl(appRoutingNames.STORE);
  }
}
