import { Component, Input } from '@angular/core';

import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'app-error-feedback',
  templateUrl: './error-feedback.component.html',
})
export class ErrorFeedbackComponent {
  @Input() appFormControl: AbstractControl | AbstractControlDirective;
  @Input() appErrorParams: { [rule: string]: any } = {};

  @Input() messageText: string;
  @Input() messageParams: any = {};

  get hasError() {
    return (
      this.appFormControl &&
      this.appFormControl.invalid &&
      (this.appFormControl.dirty || this.appFormControl.touched) &&
      this.appFormControl.errors
    );
  }

  constructor() {}
}
