import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserNgrxService } from '@app/users/services/user-ngrx.service';

@Component({
  selector: 'users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss'],
})
export class UsersFilterComponent {
  public isShowed = false;
  public filtersForm = new FormGroup({
    search: new FormControl('', []),
    company_name: new FormControl('', []),
    first_name: new FormControl('', []),
    last_name: new FormControl('', []),
    order_by: new FormControl('', []),
    active: new FormControl('', []),
    username: new FormControl('', []),
  });

  constructor(private userNgrxService: UserNgrxService) {
    //
  }

  onSubmit() {
    if (!this.filtersForm.valid) {
      return;
    }
    this.userNgrxService.dispatchSetFilters(this.filtersForm.value);
  }
}
