import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { UnAuthGuard } from '@app/core/guards/unauth.guard';
import { appRoutingNames } from '@app/app-routing-names';
import { authRoutingNames } from '@app/auth/auth-routing-names';
import { AccountGuard } from '@app/core/guards/account.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: `${appRoutingNames.AUTH}/${authRoutingNames.LOGIN}`,
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: appRoutingNames.DASHBOARD,
        canLoad: [AuthGuard, AccountGuard],
        loadChildren: () => import('@app/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: appRoutingNames.STORE,
        canLoad: [AuthGuard],
        loadChildren: () => import('@app/stores/stores.module').then(m => m.StoresModule),
      },
      {
        path: appRoutingNames.USER,
        canLoad: [AuthGuard],
        loadChildren: () => import('@app/users/users.module').then(m => m.UsersModule),
      },
      {
        path: appRoutingNames.AUTH,
        canLoad: [UnAuthGuard],
        loadChildren: () => import('@app/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: appRoutingNames.PAGES,
        loadChildren: () => import('@app/static-pages/static-pages.module').then(m => m.StaticPagesModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: `${appRoutingNames.AUTH}/${authRoutingNames.LOGIN}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  //
}
