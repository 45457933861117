import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { usersActions } from '@app/users/ngrx/actions';
import { UserProvider } from '@app/users/services/user.provider';
import { UserService } from '@app/users/services/user.service';
import { authActions } from '@app/auth/ngrx/actions';

@Injectable()
export class UsersEffect {
  constructor(private actions$: Actions, private userProvider: UserProvider, private userService: UserService) {
    //
  }

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(usersActions.getUsers.GET_USERS),
      switchMap(({ payload }) =>
        this.userProvider.getAll(payload).pipe(
          switchMap(user => of(new usersActions.getUsers.GetUsersCompletedAction(user))),
          catchError(error => of(new usersActions.getUsers.GetUsersFailedAction(error))),
        ),
      ),
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(usersActions.updateUser.UPDATE_USER),
      switchMap(({ payload: { id, user } }) =>
        this.userProvider.updateById(id, user).pipe(
          switchMap(user => of(new usersActions.updateUser.UpdateUserCompletedAction(user))),
          catchError(error => of(new usersActions.updateUser.UpdateUserFailedAction(error))),
        ),
      ),
    );
  });

  impersonate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(usersActions.impersonate.IMPERSONATE),
      switchMap(({ payload }) =>
        this.userProvider.impersonate(payload).pipe(
          switchMap(user => of(new usersActions.impersonate.ImpersonateCompletedAction(user))),
          catchError(error => of(new usersActions.impersonate.ImpersonateFailedAction(error))),
        ),
      ),
    );
  });

  offImpersonate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(usersActions.offImpersonate.OFF_IMPERSONATE),
      switchMap(({ payload }) =>
        this.userProvider.offImpersonate(payload).pipe(
          switchMap(user => of(new usersActions.offImpersonate.OffImpersonateCompletedAction(user))),
          catchError(error => of(new usersActions.offImpersonate.OffImpersonateFailedAction(error))),
        ),
      ),
    );
  });

  impersonateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(usersActions.impersonate.IMPERSONATE_COMPLETED),
        switchMap(({ payload }) => this.userService.postImpersonate(payload)),
      );
    },
    { dispatch: false },
  );

  offImpersonateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(usersActions.offImpersonate.OFF_IMPERSONATE_COMPLETED),
        switchMap(({ payload }) => this.userService.postOffImpersonate(payload)),
      );
    },
    { dispatch: false },
  );
}
