import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { httpLoaderFactory } from '@app/core/factories/translate.factory';
import { AppNgrxModule } from '@app/core/store/app.ngrx.module';
import { AppLayoutModule } from '@app/layout/layout.module';
import { SharedModule } from '@app/shared/shared.module';
import { ValidationModule } from '@app/core/validation/validation.module';
import { HttpErrorInterceptor } from '@app/core/interceptors/http-error.interceptor';
import { HttpRequestInterceptor } from '@app/core/interceptors/http-request.interceptor';
import { ToastsModule } from '@app/shared/modules/toasts/toasts.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppNgrxModule,
    AppRoutingModule,
    CoreModule,
    ToastsModule,
    AppLayoutModule,
    MatSidenavModule,
    SharedModule,
    ValidationModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
  //
}
