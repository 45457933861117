import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/ngrx/reducers';
import { AuthState } from '@app/auth/ngrx/reducers';

export const getActivationState = createSelector(fromFeature.getAuthState, (state: AuthState) => state.activation);

export const getCompleted = createSelector(getActivationState, (state: any) => state.completed);

export const getErrors = createSelector(getActivationState, (state: any) => state.errors);
