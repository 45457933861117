import { Injectable } from '@angular/core';
import { AccountFinalizeModalComponent } from '@app/account/modals/account-finalize/account-finalize.modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AccountService {
  constructor(public dialog: MatDialog) {
    //
  }

  showFinalize() {
    const dialogRef = this.dialog.open(AccountFinalizeModalComponent, { disableClose: true });

    return dialogRef.afterClosed();
  }
}
