import { ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken } from '@angular/core';

const password = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(8),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 8 },
    // maxlength: { max: 40 }
  },
};

const firstName = {
  rules: Validators.compose([
    Validators.required,
    // Validators.minLength(2),
    // Validators.maxLength(128),
  ]),
  params: {
    // minlength: { min: 2 },
    // maxlength: { max: 128 }
  },
};

const lastName = {
  rules: Validators.compose([
    Validators.required,
    // Validators.minLength(2),
    // Validators.maxLength(128),
  ]),
  params: {
    // minlength: { min: 2 },
    // maxlength: { max: 128 }
  },
};

const username = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(7),
    // Validators.maxLength(128),
  ]),
  params: {
    minlength: { min: 7 },
    // maxlength: { max: 128 }
  },
};

const email = {
  rules: Validators.compose([Validators.required, Validators.email]),
  params: {},
};

const company = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(7),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 7 },
    // maxlength: { max: 40 }
  },
};

const city = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(2),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 2 },
    // maxlength: { max: 40 }
  },
};

const state = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(2),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 2 },
    // maxlength: { max: 40 }
  },
};

const zip = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(5),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 5 },
    // maxlength: { max: 40 }
  },
};

const primaryPhone = {
  rules: Validators.compose([
    Validators.required,
    Validators.minLength(7),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 7 },
    // maxlength: { max: 40 }
  },
};

const phone = {
  rules: Validators.compose([
    Validators.minLength(7),
    // Validators.maxLength(40),
  ]),
  params: {
    minlength: { min: 7 },
    // maxlength: { max: 40 }
  },
};

const storeName = {
  rules: Validators.compose([Validators.required]),
  params: {},
};

const storeNickname = {
  rules: Validators.compose([Validators.required]),
  params: {},
};

const storeContact = {
  rules: Validators.compose([Validators.required]),
  params: {},
};

const storeAddress = {
  rules: Validators.compose([Validators.required]),
  params: {},
};

const storeTimezone = {
  rules: Validators.compose([]),
  params: {},
};

export interface IFormControlConfig {
  rules: ValidatorFn;
  params: any;
}

export const validationCompose = {
  password,
  firstName,
  lastName,
  username,
  email,
  company,
  city,
  state,
  zip,
  phone,
  primaryPhone,
  storeName,
  storeNickname,
  storeContact,
  storeAddress,
  storeTimezone,
};

export interface IAppFormValidation {
  password: IFormControlConfig;
  firstName: IFormControlConfig;
  lastName: IFormControlConfig;
  email: IFormControlConfig;
  username: IFormControlConfig;
  company: IFormControlConfig;
  city: IFormControlConfig;
  state: IFormControlConfig;
  zip: IFormControlConfig;
  phone: IFormControlConfig;
  primaryPhone: IFormControlConfig;
  storeName: IFormControlConfig;
  storeNickname: IFormControlConfig;
  storeContact: IFormControlConfig;
  storeAddress: IFormControlConfig;
  storeTimezone: IFormControlConfig;

  [name: string]: IFormControlConfig;
}

// @todo rename to base-config
export const APP_FORM_VALIDATION_CONFIG: IAppFormValidation = {
  password,
  firstName,
  lastName,
  username,
  email,
  company,
  city,
  state,
  zip,
  phone,
  primaryPhone,
  storeName,
  storeNickname,
  storeContact,
  storeAddress,
  storeTimezone,
};

// @todo rename to config
export const APP_FORM_VALIDATION = new InjectionToken<IAppFormValidation>('app.form.validation');
