import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GraphsModule } from '@app/shared/modules/graphs/graphs.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { ValidationModule } from '@app/core/validation/validation.module';
import { AppFormsModule } from '@app/shared/modules/forms/forms.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { StaticAlertComponent } from '@app/shared/components/static-alert/static-alert.component';
import { MatMenuModule } from '@angular/material/menu';
import { CalloutComponent } from '@app/shared/components/callout/callout.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { GeneralModalErrorComponent } from './components/general-modal-error/general-modal-error.component';

@NgModule({
  declarations: [SafeHtmlPipe, StaticAlertComponent, CalloutComponent, PromptDialogComponent, GeneralModalErrorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ValidationModule,
    AppFormsModule,
    FlexLayoutModule,

    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSortModule,

    ValidationModule,
    AppFormsModule,
    GraphsModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ValidationModule,
    AppFormsModule,
    FlexLayoutModule,

    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSortModule,

    ValidationModule,
    AppFormsModule,
    SafeHtmlPipe,
    GraphsModule,

    StaticAlertComponent,
    CalloutComponent,
    SafeHtmlPipe,
    PromptDialogComponent,
    GeneralModalErrorComponent,
  ],
})
export class SharedModule {}
