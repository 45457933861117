<div [ngClass.md]="'md'">
	<div class="copyright">
		<div>
      © 2023 Heartland, a Global Payments company (NYSE: GPN). All rights reserved.
		</div>
		<div>
      Heartland is a registered ISO of Wells Fargo Bank, N.A., Concord, CA, and The Bancorp Bank, Philadelphia, PA.
		</div>
	</div>
	<div class="links">
		<a href="https://www.heartland.us/terms-of-use" target="_blank">Terms of Use</a>
		<a href="https://www.heartland.us/privacy-policy" target="_blank">Privacy Policy</a>
		<a href="https://hpos.heartland.us/api/forms/eulas/hposlicense.html" target="_blank">EULA</a>
	</div>
</div>
