import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLService } from '@app/core/services/url.service';
import { UrlConst } from '@app/core/constants/url.const';

@Injectable()
export class AccountProvider {
  constructor(private http: HttpClient, private urlService: URLService) {
    //
  }

  setSchema(payload) {
    return this.http.post(this.urlService.generate(UrlConst.SCHEMA), payload);
  }

  getSchema() {
    return this.http.get(this.urlService.generate(UrlConst.SCHEMA));
  }
}
