import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Toast } from '@app/core/types/toast';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  public types = Toast.EToastTypes;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackbar: MatSnackBarRef<ToastComponent>) {
    //
  }
}
