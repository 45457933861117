import { IActions, setAction } from '@app/core/store/actions.generator';
import { accountNgrxModuleName } from '@app/account/ngrx/account-ngrx.const';

export const termsActions: {
  getTerms: IActions;
  setTermsAgree: IActions;
} = {
  getTerms: setAction(accountNgrxModuleName, ['Get', 'Terms']),
  setTermsAgree: setAction(accountNgrxModuleName, ['Set', 'Terms', 'Agree']),
};
