import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService } from '@app/auth/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public isAuthorized$ = this.authService.isAuthorized$;
  private pageUrl$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(({ urlAfterRedirects }: NavigationEnd) => {
      const parts = urlAfterRedirects.split('\/').filter(i => !!i);
      return ['auth', 'pages'].includes(parts[0]) ? parts[1] : null;
    }),
    filter(i => !!i),
    startWith('login'),
  );
  public isLoginShowed$ = this.pageUrl$.pipe(map(url => ['register', 'reset-password', 'landing'].includes(url)));
  public isRegistrationShowed$ = this.pageUrl$.pipe(map(url => ['login', 'reset-password', 'landing'].includes(url)));
  @Output() burgerClicked: EventEmitter<boolean> = new EventEmitter();
  @Input() version;

  constructor(public authService: AuthService, private router: Router) {}

  public onBurgerClicked() {
    this.burgerClicked.emit(true);
  }
}
