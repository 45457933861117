import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './terms.modal.component.html',
  styleUrls: ['./terms.modal.component.scss'],
})
export class TermsModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    //
  }
}
