import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '@app/shared/modules/terms/ngrx/selectors/terms.selector';
import { TermsState } from '@app/shared/modules/terms/ngrx/reducers';
import { termsActions } from '@app/shared/modules/terms/ngrx/actions';

@Injectable()
export class TermsNgrxService {
  public terms$ = this.store.pipe(select(fromSelectors.getTerms));

  constructor(private store: Store<TermsState>) {
    //
  }

  dispatchGetTerms() {
    this.store.dispatch(new termsActions.getTerms.GetTermsAction());
  }

  dispatchSetTermsAgree() {
    this.store.dispatch(new termsActions.setTermsAgree.SetTermsAgreeAction());
  }
}
