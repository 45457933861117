import { NgModule } from '@angular/core';

import { AuthRoutingModule } from '@app/auth/auth.routing.module';
import { LoginPageComponent } from '@app/auth/pages/login/login.page.component';
import { AuthProvider } from '@app/auth/services/auth.provider';
import { AuthNgrxModule } from '@app/auth/ngrx/auth-ngrx.module';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { AuthService } from '@app/auth/services/auth.service';
import { RegisterPageComponent } from '@app/auth/pages/register/register.page.component';
import { SharedModule } from '@app/shared/shared.module';
import { AuthLayoutComponent } from '@app/auth/layout/auth-layout.component';
import { ActivatePageComponent } from '@app/auth/pages/activate/activate.page.component';
import { AuthFooterComponent } from '@app/auth/components/auth-footer/auth-footer.component';
// eslint-disable-next-line max-len
import { ResetPasswordRequestPageComponent } from '@app/auth/pages/reset-password-request.page.component/reset-password-request.page.component';
import { ResetPasswordPageComponent } from '@app/auth/pages/reset-password/reset-password.page.component';
import { TermsModule } from '@app/shared/modules/terms/terms.module';
import { LoginPasscodeFormComponent } from '@app/auth/components/login-passcode-form/login-passcode-form.component';
import { LoginPhoneFormComponent } from '@app/auth/components/login-phone-form/login-phone-form.component';
import { LoginCredentialsFormComponent } from '@app/auth/components/login-credentials-form/login-credentials-form.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    RegisterPageComponent,
    AuthLayoutComponent,
    ActivatePageComponent,

    AuthFooterComponent,
    ResetPasswordRequestPageComponent,
    ResetPasswordPageComponent,
    LoginPhoneFormComponent,
    LoginCredentialsFormComponent,
    LoginPasscodeFormComponent,
  ],
  imports: [AuthRoutingModule, AuthNgrxModule, SharedModule, TermsModule],
  providers: [AuthProvider, AuthService, AuthNgrxService],
})
export class AuthModule {}
