import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/ngrx/reducers';
import { Auth } from '@app/core/types/auth';
import { AuthState } from '@app/auth/ngrx/reducers';

export const getCompleteCoreState = createSelector(fromFeature.getAuthState, (state: AuthState) => state.session);

export const getPending = createSelector(getCompleteCoreState, (state: Auth.IAuthStoreState) => state.loading);

export const getCurrentUser = createSelector(getCompleteCoreState, (state: Auth.IAuthStoreState) => state.currentUser);

export const getAdditionalInfo = createSelector(getCompleteCoreState, (state: Auth.IAuthStoreState) => state.additionalInfo);

export const getImpersonated = createSelector(getCompleteCoreState, (state: Auth.IAuthStoreState) => state.impersonated);

export const getMfaInfo = createSelector(getCompleteCoreState, (state: Auth.IAuthStoreState) => state.mfa);
