<app-toolbar (burgerClicked)="drawerTrigger$.next(true)"></app-toolbar>
<div class="version">{{ "" || version }}</div>
<mat-drawer-container class="mat-accent" autosize>
    <mat-drawer-content>
        <app-sidebar [drawerTrigger]="drawerTrigger$"></app-sidebar>
        <div class="page-wrapper" [class.with-side]="isAuthorized$ | async" [ngClass.xs]="'xs'">
            <div class="content">
                <flash></flash>
                <router-outlet></router-outlet>
            </div>
			<app-footer></app-footer>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
