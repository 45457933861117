import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from '@app/auth/pages/login/login.page.component';
import { RegisterPageComponent } from '@app/auth/pages/register/register.page.component';
import { authRoutingNames } from '@app/auth/auth-routing-names';
import { AuthLayoutComponent } from '@app/auth/layout/auth-layout.component';
import { ActivatePageComponent } from '@app/auth/pages/activate/activate.page.component';
// eslint-disable-next-line max-len
import { ResetPasswordRequestPageComponent } from '@app/auth/pages/reset-password-request.page.component/reset-password-request.page.component';
import { ResetPasswordPageComponent } from '@app/auth/pages/reset-password/reset-password.page.component';
import { UnAuthGuard } from '@app/core/guards/unauth.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [UnAuthGuard],
    canActivate: [UnAuthGuard],
    children: [
      { path: authRoutingNames.LOGIN, component: LoginPageComponent },
      { path: authRoutingNames.REGISTER, component: RegisterPageComponent },
      { path: authRoutingNames.ACTIVATE, component: ActivatePageComponent },
      { path: authRoutingNames.RESET_REQUEST, component: ResetPasswordRequestPageComponent },
      { path: authRoutingNames.RESET, component: ResetPasswordPageComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
