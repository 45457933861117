export namespace Dashboard {
  export enum ERequestTypes {
    ALL_SALES = 'all_sales',
    HOURLY_AMOUNT = 'hourly_sales',
    // HOURLY_ITEMS_SOLD = 'hourly_items_sold',
    // HOURLY_TRANSACTIONS = 'hourly_transactions',
    GROSS_SALES = 'gross_sales',
    NET_SALES = 'net_sales',
    TODATE_SALES = 'todate_sales',
  }

  export enum ESalesType {
    AMOUNT = 'amount',
    ITEMS_SOLD = 'items_sold',
    TRANSACTIONS = 'transactions',
    TODAY = 'today',
    ALL_DAY = 'all_day',
    DAY_TIME = 'day_time',
    NIGHT_TIME = 'night_time',
    EVENING_TIME = 'evening_time',
    LAST_7 = 'last_7',
    LAST_14 = 'last_14',
    LAST_30 = 'last_30',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
  }

  export interface IDatePickerOptions {
    isToday: boolean;
    maxDate?: string;
  }

  export interface IRequest {
    id: string;
    params: {
      date: string;
      type: string;
    };
  }

  export interface IData {
    summary: ISummary;
    department: IDepartment;
    hourly: IHourly;
    last_updated: any;
    gross: IGrossDaysPrior;
    todate: ITodate;
    net: INetDaysPrior;
  }

  export interface ISummary {
    gross_dollar: number;
    gross_percent: number;
    guest_avg: number;
    guest_count: number;
    last_year: string;
    net_sales: number;
    oldest_date: string;
    transaction_avg: number;
    transaction_count: number;
  }

  export interface IHourly {
    amount: [number];
    items_sold: [number];
    transactions: [number];
  }

  export interface ITodate {
    month: IRangeYear;
    week: IRangeYear;
    year: IRangeYear;
  }

  export interface IRangeYear {
    this_year: number;
    last_year: number;
  }

  export interface IDepartment {
    today: Array<{
      value: number;
      label: string;
    }>;
  }

  export interface IGrossDaysPrior {
    today: IGrossTodayPrior;
    last_7: IGrossLastDaysPrior;
    last_14: IGrossLastDaysPrior;
    last_30: IGrossLastDaysPrior;
  }

  export interface IGrossTodayPrior {
    cost: number;
    date: string;
    profit: number;
    sales: number;
  }

  export interface IGrossLastDaysPrior {
    cost: number;
    date_end: string;
    date_start: string;
    profit: number;
    sales: number;
  }

  export interface INetDaysPrior {
    today: INetTodayPrior;
    last_7: INetLastDaysPrior;
    last_14: INetLastDaysPrior;
    last_30: INetLastDaysPrior;
  }

  export interface INetTodayPrior {
    date: string;
    this_year: number;
    last_year: number;
  }

  export interface INetLastDaysPrior {
    last_year: number;
    this_year: number;
    date_end: string;
    date_start: string;
  }

  export interface IGraphData {
    labels: any;
    datasets: any;
    callbacks?: any;
  }
}
