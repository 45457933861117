<mat-drawer #drawer *ngIf="isAuthorized$ | async" [ngClass.xs]="'xs'" opened="true" [class.closed]="closed">
	<mat-list>
		<mat-list-item class="dashboard">
			<button mat-button routerLink="dashboard">
				<mat-icon>insert_chart_outlined</mat-icon>
				<span class="text-wrapper">{{ "layout.sidebar.dashboard" | translate }}</span>
			</button>
		</mat-list-item>
		<mat-list-item class="accordion-item users">
			<button class="btn-choose choose-1" mat-button routerLink="users">
				<mat-icon>create</mat-icon>
				<span class="text-wrapper">{{ "layout.sidebar.users" | translate }}</span>
			</button>
			<mat-accordion class="btn-choose choose-2">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<button mat-icon-button>
								<mat-icon>create</mat-icon>
							</button>
							<span class="text-wrapper">{{ "layout.sidebar.account_settings" | translate }}</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="expansion-panel-body">
						<button mat-button routerLink="users">
							<mat-icon>person_outline</mat-icon>
							<span class="text-wrapper">{{ "layout.sidebar.users" | translate }}</span>
						</button>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-list-item>
		<mat-list-item class="stores">
			<button mat-button routerLink="stores">
				<mat-icon>settings</mat-icon>
				<span class="text-wrapper">{{ "layout.sidebar.stores" | translate }}</span>
			</button>
		</mat-list-item>
	</mat-list>
</mat-drawer>
