import { NgModule } from '@angular/core';

import { reducers } from '@app/users/ngrx/reducers';
import { effects } from '@app/users/ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userNgrxModuleName } from '@app/users/ngrx/users-ngrx.const';

@NgModule({
  imports: [StoreModule.forFeature(userNgrxModuleName, reducers), EffectsModule.forFeature(effects)],
})
export class UsersNgrxModule {
  //
}
