export namespace CurrentUser {
  export interface ICurrentUser {
    username: string;
    store_count: number;
    account: IAccount;
    features: any[];
    account_id: number;
    active: boolean;
    preferred_store: any;
    isSupport: boolean;
    id: number;
    isAdmin: boolean;
  }

  export interface IAccount {
    status: EAccountStatus;
    store_count: number;
    id: number;
    name: string;
  }

  export enum EAccountStatus {
    new = 'new',
    ready = 'ready',
  }
}
