import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { ErrorFeedbackComponent } from '@app/core/validation/components/error-feedback/error-feedback.component';
import { APP_FORM_ERROR_VIEW, APP_FORM_ERROR_VIEW_CONFIG, ErrorViewDirective } from '@app/core/validation/directives/error-view-control';
import { FormObservableDirective } from '@app/core/validation/directives/form-observable';
import { APP_FORM_VALIDATION, APP_FORM_VALIDATION_CONFIG } from '@app/core/validation/validation.compose';
import { ValidationService } from '@app/core/validation/validation.service';

@NgModule({
  imports: [CommonModule, TranslateModule, OverlayModule, PortalModule],
  declarations: [ErrorFeedbackComponent, ErrorViewDirective, FormObservableDirective],
  exports: [ErrorFeedbackComponent, ErrorViewDirective, FormObservableDirective],
})
export class ValidationModule {
  static forRoot(): ModuleWithProviders<ValidationModule> {
    return {
      ngModule: ValidationModule,
      providers: [
        {
          provide: APP_FORM_VALIDATION,
          useValue: APP_FORM_VALIDATION_CONFIG,
        },
        {
          provide: APP_FORM_ERROR_VIEW,
          useValue: APP_FORM_ERROR_VIEW_CONFIG,
        },
        ValidationService,
      ],
    };
  }
}
