import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Dashboard } from '@app/core/types/dashboard';
import { Dates } from '@app/core/types/dates';
import { DashboardGraphsService } from '@app/dashboard/services/dashboard-graphs.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardNgrxService } from '@app/dashboard/services/dashboard-ngrx.service';
import { BehaviorSubject, combineLatest, delay, Observable, Subject } from "rxjs";
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment';

@Component({
  templateUrl: './dashboard.page.component.html',
  styleUrls: ['./dashboard.page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject();
  public breakpoint: number;
  private requestedType = Dashboard.ERequestTypes.ALL_SALES;

  public types = Dashboard.ERequestTypes;
  public formFilters: FormGroup = this.dashboardService.formFilters;
  public isToday$: BehaviorSubject<boolean> = this.dashboardService.isToday$;
  public options$ = this.dashboardService.options$;
  public dashboardData$: Observable<Dashboard.IData> = this.dashboardNgrxService.dashboardData$;
  public store$ = this.dashboardService.store$;
  public filters$ = this.dashboardNgrxService.getFilters$.pipe(
    delay(0), // translations issue on refresh
    tap(filters => this.dashboardService.updateFormFilters(filters))
  );

  public lastUpdated$ = this.dashboardData$.pipe(
    filter(data => !!data?.last_updated),
    map(({ last_updated }) => moment(last_updated).format(Dates.formats.dateTimeLocalSeconds)),
  );

  public hourlyGraphSets$ = combineLatest([this.dashboardData$, this.filters$]).pipe(
    filter(([graphsData]) => !!graphsData?.hourly),
    map(([{ hourly }, filters]) => this.dashboardGraphsService.hourlyModifyData(filters, hourly)),
  );

  public toDateGraphSets$ = combineLatest([this.dashboardData$, this.filters$]).pipe(
    filter(([graphsData]) => !!graphsData?.todate),
    map(([{ todate }, filters]) => this.dashboardGraphsService.toDateModifyData(filters, todate)),
  );

  public departmentGraphSets$ = this.dashboardData$.pipe(
    filter(graphsData => !!graphsData?.department),
    map(({ department }) => this.dashboardGraphsService.departmentModifyData(department)),
  );

  public grossGraphSets$ = combineLatest([this.dashboardData$, this.filters$]).pipe(
    filter(([graphsData]) => !!graphsData?.gross),
    map(([{ gross }, filters]) => this.dashboardGraphsService.grossModifyData(filters, gross)),
  );

  public netGraphSets$ = combineLatest([this.dashboardData$, this.filters$]).pipe(
    filter(([graphsData]) => !!graphsData?.net),
    map(([{ net }, filters]) => this.dashboardGraphsService.netModifyData(filters, net)),
  );

  constructor(
    private dashboardService: DashboardService,
    private dashboardGraphsService: DashboardGraphsService,
    private dashboardNgrxService: DashboardNgrxService,
  ) {}

  ngOnInit() {
    this.breakpoint = window.innerWidth <= 1000 ? 1 : 3;

    combineLatest([this.store$, this.filters$])
      .pipe(
        filter(([store]) => !!store),
        map(([store]) => ({
          id: store,
          params: {
            date: this.dashboardService.getDate.value,
            type: this.requestedType,
          },
        })),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((data: Dashboard.IRequest) => {
        data.params.type === Dashboard.ERequestTypes.ALL_SALES
          ? this.dashboardNgrxService.dispatchGetGraphs(data)
          : this.dashboardNgrxService.dispatchGetGraph(data);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 1000 ? 1 : 3;
  }

  dispatchFilterChange(type: Dashboard.ERequestTypes, refresh = false) {
    this.requestedType = type;
    if (this.requestedType === this.types.ALL_SALES) {
      const dateField = this.dashboardService.getDate;
      dateField.setValue(moment(dateField.value).format(Dates.formats.serverDate));
    }
    this.dashboardNgrxService.dispatchFilters({ ...this.formFilters.value, refresh });
  }

  refreshData() {
    this.dashboardService.getDate.setValue(moment().format(Dates.formats.serverDate));
    this.dispatchFilterChange(Dashboard.ERequestTypes.ALL_SALES, true);
  }
}
