import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLService } from '@app/core/services/url.service';
import { UrlConst } from '@app/core/constants/url.const';
import { Observable } from 'rxjs';

@Injectable()
export class AuthProvider {
  constructor(private http: HttpClient, private urlService: URLService) {}

  login(payload) {
    return this.http.post(this.urlService.generate(UrlConst.LOGIN), payload);
  }

  logout() {
    return this.http.post(this.urlService.generate(UrlConst.LOGOUT), {});
  }

  registerCheck(payload) {
    return this.register({ ...payload, dry_run: true });
  }

  register(payload) {
    return this.http.post(this.urlService.generate(UrlConst.REGISTER), payload);
  }

  requestAuthToken(payload): Observable<any> {
    return this.http.post(this.urlService.generate(UrlConst.AUTH_TOKEN), payload);
  }

  requestPasswordReset(payload): Observable<any> {
    return this.http.post(this.urlService.generate(UrlConst.RESET_PASSWORD_REQUEST), payload);
  }

  resetPassword(payload): Observable<any> {
    return this.http.post(this.urlService.generate(UrlConst.RESET_PASSWORD), payload);
  }

  changePassword(payload): Observable<any> {
    return this.http.post(this.urlService.generate(UrlConst.CHANGE_PASSWORD), payload);
  }

  getCurrentUser() {
    return this.http.get(this.urlService.generate(UrlConst.CURRENT));
  }

  activate(payload) {
    return this.http.get(this.urlService.generate(UrlConst.ACTIVATE, payload, true), { responseType: 'text' });
  }

  validateResetPassword(payload) {
    return this.http.get(this.urlService.generate(UrlConst.VALIDATE_RESET_PASSWORD, payload, true), { responseType: 'text' });
  }
}
