import { NgModule } from '@angular/core';

import { ProfileModalComponent } from '@app/account/modals/profile/profile.modal.component';
import { ProfileBtnComponent } from '@app/account/components/profile-btn/profile-btn.component';
import { SharedModule } from '@app/shared/shared.module';
import { AccountFinalizeModalComponent } from '@app/account/modals/account-finalize/account-finalize.modal.component';
import { AccountProvider } from '@app/account/services/account.provider';
import { AccountNgrxService } from '@app/account/services/account-ngrx.service';
import { AccountNgrxModule } from '@app/account/ngrx/account-ngrx.module';
import { UsersModule } from '@app/users/users.module';
import { TermsModule } from '@app/shared/modules/terms/terms.module';
import { AccountService } from '@app/account/services/account.service';

@NgModule({
  declarations: [ProfileModalComponent, ProfileBtnComponent, AccountFinalizeModalComponent],
  providers: [AccountProvider, AccountNgrxService, AccountService],
  exports: [ProfileBtnComponent],
  imports: [SharedModule, AccountNgrxModule, UsersModule, TermsModule],
})
export class AccountModule {}
