<ng-container *ngIf="!completed">
	<h2>{{ 'pages.reset.title' | translate }}</h2>
	<p>{{ 'pages.reset.subtitle' | translate }}</p>
	<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>lock</mat-icon>
				</div>
				<input placeholder="{{ 'pages.reset.form.new_password' | translate }}"
				       tabindex="1"
				       appWithErrorView="password"
				       type="text"
				       formControlName="new_password"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>lock</mat-icon>
				</div>
				<input placeholder="{{ 'pages.reset.form.confirm_password' | translate }}"
				       tabindex="1"
				       appWithErrorView="password"
				       type="text"
				       formControlName="new_password_confirm"
				       class="form-control">
			</div>
			<app-error-feedback
					*ngIf="resetPasswordForm.errors?.passwords_not_match"
					[appFormControl]='resetPasswordForm'
					[appErrorParams]="{}">
			</app-error-feedback>
		</div>
		<div class="form-group">
			<button mat-raised-button class="btn btn-primary" type="submit" [disabled]="!resetPasswordForm.valid">
				{{ 'pages.reset.form.submit' | translate }}
			</button>
		</div>
	</form>
</ng-container>

<static-alert *ngIf="completed"
              title="pages.reset.completed.title"
              message="pages.reset.completed.message"
              [params]="resetPasswordForm.value"></static-alert>

<auth-footer place="reset"></auth-footer>

