import { Component, Input } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';

@Component({
  selector: 'summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss'],
})
export class SummaryInfoComponent {
  @Input() summary: Dashboard.ISummary;

  constructor() {}
}
