import { Injectable } from '@angular/core';
import { dashboardActions } from '@app/dashboard/ngrx/actions';
import { DashboardProvider } from '@app/dashboard/services/dashboard.provider';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class DashboardEffect {
  constructor(private actions$: Actions, private dashboardProvider: DashboardProvider) {}

  getGraphs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.getGraphs.GET_GRAPHS),
      switchMap(({ payload }) =>
        this.dashboardProvider.getGraphs(payload).pipe(
          switchMap(data => of(new dashboardActions.getGraphs.GetGraphsCompletedAction(data))),
          catchError(error => of(new dashboardActions.getGraphs.GetGraphsFailedAction(error))),
        ),
      ),
    );
  });

  getGraph$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.getGraph.GET_GRAPH),
      switchMap(({ payload }) =>
        this.dashboardProvider.getGraph(payload).pipe(
          switchMap(data => of(new dashboardActions.getGraph.GetGraphCompletedAction(data))),
          catchError(error => of(new dashboardActions.getGraph.GetGraphFailedAction(error))),
        ),
      ),
    );
  });
}
