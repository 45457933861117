import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { termsActions } from '@app/shared/modules/terms/ngrx/actions';

export const defaultState: any = {
  loading: false,
  terms: null,
  error: null,
};

export function termsReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case termsActions.getTerms.GET_TERMS: {
      return update(state, {
        terms: { $set: null },
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case termsActions.getTerms.GET_TERMS_COMPLETED: {
      const { terms } = (action as any).payload;
      return update(state, {
        terms: { $set: terms },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case termsActions.getTerms.GET_TERMS_FAILED: {
      return update(state, {
        terms: { $set: null },
        loading: { $set: false },
        error: { $set: (action as any).payload },
      });
    }

    default:
      return state;
  }
}
