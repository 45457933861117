<ng-container *ngIf="!(authNgrxService.isActivationCompleted$ | async)">
	<h2>{{ 'pages.activate.title' | translate }}</h2>
	<p>{{ 'pages.activate.subtitle' | translate }}</p>
	<form [formGroup]="activateForm" (ngSubmit)="onSubmit()">
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>mail</mat-icon>
				</div>
				<input placeholder="{{ 'pages.activate.form.email' | translate }}"
				       tabindex="1"
				       appWithErrorView
				       type="text"
				       autocomplete="email"
				       formControlName="email"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<button mat-raised-button class="btn btn-primary" type="submit" [disabled]="!activateForm.valid">
				{{ 'pages.activate.form.submit' | translate }}
			</button>
		</div>
	</form>
</ng-container>

<static-alert *ngIf="(authNgrxService.isActivationCompleted$ | async)"
              title="pages.activate.completed.title"
              message="pages.activate.completed.message"
              [params]="activateForm.value"></static-alert>

<auth-footer place="activate"></auth-footer>

