import { NgModule } from '@angular/core';
import { dashboardNgrxModuleName } from '@app/dashboard/ngrx/dashboard-ngrx.const';
import { effects } from '@app/dashboard/ngrx/effects';
import { reducers } from '@app/dashboard/ngrx/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature(dashboardNgrxModuleName, reducers), EffectsModule.forFeature(effects)],
})
export class DashboardNgrxModule {}
