import { Injectable } from '@angular/core';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { filter, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { appRoutingNames } from '@app/app-routing-names';
import { authRoutingNames } from '@app/auth/auth-routing-names';
import { CurrentUser } from '@app/core/types/current-user';

@Injectable()
export class AuthService {
  public isAuthorized$ = this.authNgrxService.user$.pipe(map(user => !!user));
  public isHasAccount$ = this.authNgrxService.user$.pipe(
    filter(user => !!user),
    map(user => user?.account?.status === CurrentUser.EAccountStatus.ready),
  );
  public isHasStore$ = this.authNgrxService.user$.pipe(
    filter(user => !!user),
    map(user => !!user?.preferred_store),
  );

  constructor(private authNgrxService: AuthNgrxService, private router: Router) {}

  public postLogin(payload) {
    let url = '/';

    if (payload.user) {
      url = payload.store_count ? appRoutingNames.DASHBOARD : appRoutingNames.STORE;
    }

    return from(this.router.navigateByUrl(url)).pipe(
      map(() => payload),
    );
  }

  public postLogout() {
    return from(this.router.navigateByUrl(`${appRoutingNames.AUTH}/${authRoutingNames.LOGIN}`));
  }
}
