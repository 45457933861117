import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TermsProvider } from '@app/shared/modules/terms/services/terms.provider';
import { termsActions } from '@app/shared/modules/terms/ngrx/actions';

@Injectable()
export class TermsEffect {
  constructor(private actions$: Actions, private termsProvider: TermsProvider) {
    //
  }

  getTerms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(termsActions.getTerms.GET_TERMS),
      switchMap(() =>
        this.termsProvider.getTerms().pipe(
          switchMap(terms => of(new termsActions.getTerms.GetTermsCompletedAction(terms))),
          catchError(error => of(new termsActions.getTerms.GetTermsFailedAction(error))),
        ),
      ),
    );
  });

  setTermsAgree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(termsActions.setTermsAgree.SET_TERMS_AGREE),
      switchMap(() =>
        this.termsProvider.setTermsAgree().pipe(
          switchMap(() => of(new termsActions.setTermsAgree.SetTermsAgreeCompletedAction())),
          catchError(error => of(new termsActions.setTermsAgree.SetTermsAgreeFailedAction(error))),
        ),
      ),
    );
  });
}
