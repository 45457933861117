import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLService } from '@app/core/services/url.service';
import { UrlConst } from '@app/core/constants/url.const';

@Injectable()
export class UserProvider {
  constructor(private http: HttpClient, private urlService: URLService) {
    //
  }

  getById(id) {
    return this.http.get(this.urlService.generate(UrlConst.USER_ONE, { id }));
  }

  getAll(params) {
    return this.http.get(this.urlService.generate(UrlConst.USER), { params });
  }

  updateById(id, data) {
    return this.http.put(this.urlService.generate(UrlConst.USER_ONE, { id }), data);
  }

  impersonate(id) {
    return this.http.put(this.urlService.generate(UrlConst.IMPERSONATE, { id }), {});
  }

  offImpersonate(id) {
    return this.http.delete(this.urlService.generate(UrlConst.IMPERSONATE, { id }), {});
  }
}
