import { NgModule } from '@angular/core';

import { reducers } from '@app/auth/ngrx/reducers';
import { effects } from '@app/auth/ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authNgrxModuleName } from '@app/auth/ngrx/auth-ngrx.const';

@NgModule({
  imports: [StoreModule.forFeature(authNgrxModuleName, reducers), EffectsModule.forFeature(effects)],
})
export class AuthNgrxModule {}
