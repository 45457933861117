import { NgModule } from '@angular/core';

import { AuthGuard } from '@app/core/guards/auth.guard';
import { URLService } from '@app/core/services/url.service';
import { AuthModule } from '@app/auth/auth.module';
import { UnAuthGuard } from '@app/core/guards/unauth.guard';
import { AccountGuard } from '@app/core/guards/account.guard';

@NgModule({
  imports: [AuthModule],
  providers: [AuthGuard, UnAuthGuard, AccountGuard, URLService],
})
export class CoreModule {}
