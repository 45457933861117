import { Component } from '@angular/core';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.scss'],
})
export class LoginPageComponent {
  public loginData = { username: null, password: null };
  public step$ = this.authNgrxService.mfaInfo$.pipe(
    map(info => {
      if (info && !(this.loginData.username && this.loginData.password)) {
        return 1;
      }

      if (info?.verifyPassCode) {
        return 3;
      }

      if (info?.requiredPhone) {
        return 2;
      }

      return 1;
    }),
  );

  constructor(public authNgrxService: AuthNgrxService) {
    this.authNgrxService.dispatchReset();
  }

  onSubmit(formData = {}) {
    this.loginData = { ...this.loginData, ...formData };
    this.authNgrxService.dispatchLogin(this.loginData);
  }
}
