<ng-container *ngIf="!(authNgrxService.isCompleted$ | async)">
	<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
		<h2>{{ 'pages.register.title' | translate }}</h2>

		<h3>{{ 'pages.register.form.personal_info' | translate }}</h3>
		<div class="form-group">
			<div class="input-group">
				<div class="icon"></div>
				<input placeholder="{{ 'pages.register.form.first_name' | translate }}"
				       appWithErrorView="firstName"
				       tabindex="1"
				       type="text"
				       formControlName="first_name"
				       class="form-control">

			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<div class="icon"></div>
				<input placeholder="{{ 'pages.register.form.last_name' | translate }}"
				       appWithErrorView="lastName"
				       tabindex="2"
				       type="text"
				       formControlName="last_name"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>mail</mat-icon>
				</div>
				<input placeholder="{{ 'pages.register.form.email' | translate }}"
				       appWithErrorView
				       tabindex="3"
				       type="text"
				       formControlName="email"
				       class="form-control">
			</div>
		</div>

		<h3>{{ 'pages.register.form.account_details' | translate }}</h3>
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>person</mat-icon>
				</div>
				<input placeholder="{{ 'pages.register.form.username' | translate }}"
				       appWithErrorView="username"
				       tabindex="4"
				       type="text"
				       autocomplete="username"
				       formControlName="username"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>lock</mat-icon>
				</div>
				<input placeholder="{{ 'pages.register.form.password' | translate }}"
				       appWithErrorView
				       tabindex="5"
				       type="password"
				       formControlName="password"
				       autocomplete="new-password"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>lock</mat-icon>
				</div>
				<input placeholder="{{ 'pages.register.form.confirm_password' | translate }}"
				       appWithErrorView="password"
				       tabindex="6"
				       type="password"
				       formControlName="password_confirm"
				       autocomplete="new-password"
				       class="form-control">
			</div>
			<app-error-feedback
					*ngIf="registerForm.errors?.passwords_not_match"
					[appFormControl]='registerForm'
					[appErrorParams]="{}">
			</app-error-feedback>
		</div>
		<div class="form-group">
			<button mat-raised-button class="btn btn-primary" type="submit" [disabled]="!registerForm.valid">
				{{ 'pages.register.form.create_account' | translate }}
			</button>
		</div>
	</form>
</ng-container>

<static-alert *ngIf="(authNgrxService.isCompleted$ | async)"
              title="pages.register.completed.title"
              message="pages.register.completed.message"
              [redirect]="loginLink"
              redirectName="pages.register.form.back_to_login"
              [params]="registerForm.value"></static-alert>

<auth-footer place="register"></auth-footer>
