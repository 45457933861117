<div mat-dialog-title>
	<h1 class="modal-title">{{ 'shared.modules.terms.modal.title' | translate }}</h1>
	<p>{{ 'shared.modules.terms.modal.last_modified' | translate }}: {{ data.updated_at | date }}</p>
	<h4 *ngIf="data.summary">{{ data.summary }}</h4>
</div>
<div mat-dialog-content>
	<blockquote class="text-warning">
		<p>{{ 'shared.modules.terms.modal.quote' | translate }}</p>
	</blockquote>
	<div [innerHtml]="data.content | safeHtml"></div>
</div>
<div mat-dialog-actions class="actions">
	<button mat-button [mat-dialog-close]="true" class="btn btn-primary">{{ 'shared.modules.terms.modal.agree' | translate }}</button>
	<button mat-button [mat-dialog-close]="false" class="btn btn-warning">{{ 'shared.modules.terms.modal.disagree' | translate }}</button>
</div>
