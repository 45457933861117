export namespace Flesh {
  export interface IFlesh {
    message: string;
    type: EFleshTypes;
  }

  export enum EFleshTypes {
    error = 'error',
    success = 'success',
    warning = 'warning',
  }
}
