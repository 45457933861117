<h2>{{ 'pages.users.title' | translate }}</h2>
<users-filter></users-filter>

<mat-card class="users-table">
	<div class="table-scroll">
		<table mat-table matSort matSortActive="created" matSortDisableClear matSortDirection="desc" [dataSource]="users$ | async" class="mat-elevation-z8">
		<ng-container matColumnDef="impersonating">
			<th mat-header-cell *matHeaderCellDef>
				<mat-icon class="imper-icon">person</mat-icon>
			</th>
			<td mat-cell *matCellDef="let user">
				<button mat-icon-button (click)="impersonate(user.id)" *ngIf="user.allow_support">
					<mat-icon>person</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="username">
			<th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>{{ 'pages.users.table.username' | translate }}</th>
			<td mat-cell *matCellDef="let user"> {{user.username}} </td>
		</ng-container>

		<ng-container matColumnDef="company_name">
			<th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>{{ 'pages.users.table.company' | translate }}</th>
			<td mat-cell *matCellDef="let user"> {{user.company_name}} </td>
		</ng-container>

		<ng-container matColumnDef="first_name">
			<th mat-header-cell *matHeaderCellDef>{{ 'pages.users.table.first' | translate }}</th>
			<td mat-cell *matCellDef="let user"> {{user.first_name}} </td>
		</ng-container>

		<ng-container matColumnDef="last_name">
			<th mat-header-cell *matHeaderCellDef>{{ 'pages.users.table.last' | translate }}</th>
			<td mat-cell *matCellDef="let user"> {{user.last_name}} </td>
		</ng-container>

		<ng-container matColumnDef="roles">
			<th mat-header-cell *matHeaderCellDef>{{ 'pages.users.table.roles' | translate }}</th>
			<td mat-cell *matCellDef="let user"> {{ user.roles | userRoles}} </td>
		</ng-container>

		<ng-container matColumnDef="active">
			<th mat-header-cell *matHeaderCellDef>{{ 'pages.users.table.active' | translate }}</th>
			<td mat-cell *matCellDef="let user">
				<mat-checkbox [checked]="user.active" (click)="userActiveStatus(user)"></mat-checkbox>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns$ | async;"></tr>
	</table>
	</div>
</mat-card>

<mat-card *ngIf="!(users$ | async)?.length" class="no-users">
	<h4>{{ 'pages.users.table.no_users' | translate }}</h4>
</mat-card>
