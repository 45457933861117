import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/dashboard/ngrx/reducers';
import { DashboardState } from '@app/dashboard/ngrx/reducers';

/*DASHBOARD SELECTOR*/
export const getDashboardCoreState = createSelector(fromFeature.getDashboardState, (state: DashboardState) => state.data);

export const getDashboardPending = createSelector(getDashboardCoreState, (state: any) => state.loading);

export const getDashboard = createSelector(getDashboardCoreState, (state: any) => state.graphs);

/*FILTERS SELECTOR*/
export const getFiltersCoreState = createSelector(fromFeature.getDashboardState, (state: DashboardState) => state.filters);

export const getFilters = createSelector(getFiltersCoreState, (state: any) => state);
