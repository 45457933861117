import { Component } from '@angular/core';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';

@Component({
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.scss'],
})
export class LoginPageComponent {
  loginForm = new FormGroup({
    username: new FormControl('', [validationCompose.email.rules]),
    password: new FormControl('', [validationCompose.password.rules]),
  });

  constructor(public authNgrxService: AuthNgrxService) {}

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }

    this.authNgrxService.dispatchLogin(this.loginForm.value);
  }
}
