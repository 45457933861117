import { IActions, setAction } from '@app/core/store/actions.generator';
import { storesNgrxModuleName } from '@app/stores/ngrx/stores-ngrx.const';

export const storesActions: {
  getStores: IActions;
  updateStore: IActions;
  getStore: IActions;
  disconnectPos: IActions;
  createStore: IActions;
  updateFilters: IActions;
} = {
  getStores: setAction(storesNgrxModuleName, ['Get', 'Stores']),
  updateStore: setAction(storesNgrxModuleName, ['Update', 'Store']),
  getStore: setAction(storesNgrxModuleName, ['Get', 'Store']),
  disconnectPos: setAction(storesNgrxModuleName, ['Disconnect', 'Pos']),
  createStore: setAction(storesNgrxModuleName, ['Create', 'Store']),
  updateFilters: setAction(storesNgrxModuleName, ['Update', 'Filters']),
};
