<form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
	<div class="form-group">
		<div class="input-group">
			<div class="icon">
				<mat-icon>phone</mat-icon>
			</div>
			<input placeholder="{{ 'pages.login.form.phone' | translate }}"
			       tabindex="3"
			       appWithErrorView
			       type="text"
			       autocomplete="phone"
			       formControlName="phone"
			       class="form-control">
		</div>
	</div>
	<div class="form-group">
		<button mat-raised-button class="btn btn-primary" type="submit" [disabled]="!phoneForm.valid">
			{{ 'pages.login.form.send_code' | translate }}
			<mat-spinner *ngIf="authNgrxService.loginPending$ | async" diameter="30"></mat-spinner>
		</button>
	</div>
</form>
