import { Injectable } from '@angular/core';
import { filter, first, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TermsModalComponent } from '@app/shared/modules/terms/modals/terms-modal/terms.modal.component';
import { TermsNgrxService } from '@app/shared/modules/terms/services/terms-ngrx.service';
import { of } from 'rxjs';

@Injectable()
export class TermsService {
  constructor(private termsNgrxService: TermsNgrxService, public dialog: MatDialog) {
    //
  }

  showTerms(postProcessing = false) {
    this.termsNgrxService.dispatchGetTerms();

    return this.termsNgrxService.terms$.pipe(
      filter(terms => !!terms),
      first(),
      switchMap(terms => this.showModal(terms)),
      switchMap(accepted => this.processTerms(accepted, postProcessing)),
    );
  }

  showModal(data) {
    const dialogRef = this.dialog.open(TermsModalComponent, { data });

    return dialogRef.afterClosed();
  }

  processTerms(accepted, postProcessing) {
    if (accepted && !postProcessing) {
      this.termsNgrxService.dispatchSetTermsAgree();
    }

    return of(accepted);
  }
}
