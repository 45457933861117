import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '@app/users/ngrx/selectors/users.selector';
import { usersActions } from '@app/users/ngrx/actions';
import { UsersState } from '@app/users/ngrx/reducers';

@Injectable()
export class UserNgrxService {
  public users$ = this.store.pipe(select(fromSelectors.getUsers));
  public filters$ = this.store.pipe(select(fromSelectors.getFilters));

  constructor(private store: Store<UsersState>) {
    //
  }

  dispatchSetFilters(payload) {
    this.store.dispatch(new usersActions.setUsersFilter.SetUsersFilterAction(payload));
  }

  dispatchGetUsers(payload) {
    this.store.dispatch(new usersActions.getUsers.GetUsersAction(payload));
  }

  dispatchChangeStatus(user) {
    this.store.dispatch(new usersActions.updateUser.UpdateUserAction({ id: user.id, user }));
  }

  dispatchImpersonate(payload) {
    this.store.dispatch(new usersActions.impersonate.ImpersonateAction(payload));
  }

  dispatchOffImpersonate(payload) {
    this.store.dispatch(new usersActions.offImpersonate.OffImpersonateAction(payload));
  }
}
