import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';
import pkg from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public drawerTrigger$: Subject<boolean> = new Subject();
  public isAuthorized$ = this.authService.isAuthorized$;
  public version = pkg.version;

  constructor(private translateService: TranslateService, private authService: AuthService) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }
}
