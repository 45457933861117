<button mat-button [matMenuTriggerFor]="menu" [class.impersonated]="isImpersonated$ | async">
	<mat-icon>person</mat-icon>
	<span class="username" [ngClass.xs]="'xs'">{{ userName$ | async }}</span>
</button>


<mat-menu #menu>
	<button mat-menu-item (click)="dispatchProfile()">
		<mat-icon>person_add_alt_1</mat-icon>
		<span>{{ 'pages.account.components.profile-btn.profile' | translate }}</span>
	</button>
	<button mat-menu-item (click)="logout()"  *ngIf="!(isImpersonated$ | async)">
		<mat-icon>logout</mat-icon>
		<span>{{ 'pages.account.components.profile-btn.logout' | translate }}</span>
	</button>
	<button mat-menu-item (click)="stopImpersonating()"  *ngIf="isImpersonated$ | async" class="stop-impersonate">
		<mat-icon>cancel</mat-icon>
		<span>{{ 'pages.account.components.profile-btn.stop_impersonating' | translate }}</span>
	</button>
</mat-menu>
