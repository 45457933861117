<ng-container *ngIf="summary && summary.gross_dollar; else noData">
    <div class="wrapper-item total border-bottom">
        <div class="label">{{'pages.dashboard.summary.gross_dollar' | translate}}:</div>
        <div class="value">{{summary.gross_dollar | currency}}</div>
    </div>
    <div class="wrapper-item border-bottom" *ngIf="summary.net_sales">
        <div class="label">{{'pages.dashboard.summary.net_sales' | translate}}:</div>
        <div class="value">{{summary.net_sales | currency}}</div>
    </div>
    <div class="wrapper-item list-items">
        <div class="list-item" *ngIf="summary.gross_percent">
            <div class="label">{{'pages.dashboard.summary.gross_percent' | translate}}:</div>
            <div class="value">{{summary.gross_percent}}</div>
        </div>
        <div class="list-item" *ngIf="summary.transaction_count">
            <div class="label">{{'pages.dashboard.summary.transaction_count' | translate}}:</div>
            <div class="value">{{summary.transaction_count}}</div>
        </div>
        <div class="list-item" *ngIf="summary.guest_avg">
            <div class="label">{{'pages.dashboard.summary.guest_avg' | translate}}:</div>
            <div class="value">{{summary.guest_avg}}</div>
        </div>
        <div class="list-item" *ngIf="summary.guest_count">
            <div class="label">{{'pages.dashboard.summary.guest_count' | translate}}:</div>
            <div class="value">{{summary.guest_count}}</div>
        </div>
        <div class="list-item" *ngIf="summary.transaction_avg">
            <div class="label">{{'pages.dashboard.summary.transaction_avg' | translate}}:</div>
            <div class="value">{{summary.transaction_avg | currency}}</div>
        </div>
    </div>
</ng-container>

<ng-template #noData>
    <div class="no-data">{{'pages.dashboard.no-data' | translate}}</div>
</ng-template>
