import { Dashboard } from '@app/core/types/dashboard';
import { Dates } from '@app/core/types/dates';
import { dashboardActions } from '@app/dashboard/ngrx/actions';
import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import moment from 'moment';

export const defaultState = {
  date: moment().format(Dates.formats.serverDate),
  hourly: Dashboard.ESalesType.AMOUNT,
  hourly_range: Dashboard.ESalesType.ALL_DAY,
  gross: Dashboard.ESalesType.TODAY,
  net: Dashboard.ESalesType.TODAY,
  todate: Dashboard.ESalesType.WEEK,
  refresh: false
};

export function dashboardFiltersReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case dashboardActions.updateFilters.UPDATE_FILTERS: {
      return update(state, { $set: (action as any).payload });
    }

    default:
      return state;
  }
}
