export class UrlConst {
  public static get LOGIN(): string {
    return 'login';
  }

  public static get LOGOUT(): string {
    return 'logout';
  }

  public static get REGISTER(): string {
    return 'register';
  }

  public static get TERMS(): string {
    return 'terms';
  }

  public static get TERMS_AGREE(): string {
    return 'terms_agree';
  }

  public static get AUTH_TOKEN(): string {
    return 'auth_token';
  }

  public static get RESET_PASSWORD_REQUEST(): string {
    return 'reset_password_request';
  }

  public static get RESET_PASSWORD(): string {
    return 'reset_password';
  }

  public static get CHANGE_PASSWORD(): string {
    return 'change_password';
  }

  public static get SCHEMA(): string {
    return 'schema';
  }

  public static get USER(): string {
    return 'user';
  }

  public static get USER_ONE(): string {
    return 'user_one';
  }

  public static get STORE(): string {
    return 'store';
  }

  public static get STORE_ONE(): string {
    return 'store_one';
  }

  public static get STORE_POS(): string {
    return 'store_pos';
  }

  public static get IMPERSONATE(): string {
    return 'impersonate';
  }

  public static get CURRENT(): string {
    return 'current';
  }

  public static get ACTIVATE(): string {
    return 'activate';
  }

  public static get DASHBOARD(): string {
    return 'dashboard';
  }

  public static get VALIDATE_RESET_PASSWORD(): string {
    return 'validate_reset_password';
  }
}
