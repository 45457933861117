import { Component } from '@angular/core';
import { FleshService } from '@app/layout/services/flesh.service';

@Component({
  selector: 'flash',
  templateUrl: './flesh.component.html',
  styleUrls: ['./flash.component.scss'],
})
export class FleshComponent {
  constructor(public fleshService: FleshService) {}
}
