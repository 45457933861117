import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { matchPasswords } from '@app/core/validation/validators';
import { TermsService } from '@app/shared/modules/terms/services/terms.service';
import { first } from 'rxjs/operators';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';

@Component({
  templateUrl: './profile.modal.component.html',
  styleUrls: ['./profile.modal.component.scss'],
})
export class ProfileModalComponent {
  isImpersonated$ = this.authNgrxService.isImpersonated$;
  get response() {
    return {
      info: this.personalInfoForm.valid && this.personalInfoForm.dirty ? this.personalInfoForm.value : null,
      passwords: this.passwordsForm.valid && this.passwordsForm.dirty ? this.passwordsForm.value : null,
    };
  }

  public personalInfoForm = new FormGroup({
    first_name: new FormControl('', [validationCompose.firstName.rules]),
    last_name: new FormControl('', [validationCompose.lastName.rules]),
  });

  public passwordsForm = new FormGroup(
    {
      old_password: new FormControl('', [validationCompose.password.rules]),
      new_password: new FormControl('', [validationCompose.password.rules]),
      new_password_confirm: new FormControl('', [validationCompose.password.rules]),
    },
    matchPasswords,
  );

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public termsService: TermsService, public authNgrxService: AuthNgrxService) {
    const { first_name, last_name } = data;
    this.personalInfoForm.patchValue({ first_name, last_name });
  }

  showTerms() {
    this.termsService.showTerms().pipe(first()).subscribe();
    return false;
  }
}
