import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { UserNgrxService } from '@app/users/services/user-ngrx.service';
import { combineLatest, of, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { MatDialog } from '@angular/material/dialog';
import { UserActivateModalComponent } from '@app/users/modals/user-activate/user-activate.modal.component';
import { MatSort } from '@angular/material/sort';

@Component({
  templateUrl: './users.page.component.html',
  styleUrls: ['./users.page.component.scss'],
})
export class UsersPageComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public users$ = this.userNgrxService.users$;
  public isAdmin$ = this.authNgrxService.isAdmin$;
  public isSupport$ = this.authNgrxService.isSupport$;
  public isImpersonator$ = of(false);
  public isImpersonating$ = of(false);
  private ngUnsubscribe$: Subject<void> = new Subject();
  displayedColumns$ = combineLatest([this.authNgrxService.isAdmin$, this.authNgrxService.isSupport$, this.isImpersonating$]).pipe(
    map(([isAdmin, isSupport, isImpersonating]) => {
      let columns = ['username', 'company_name', 'first_name', 'last_name', 'roles'];

      if (isAdmin) {
        columns = [...columns, 'active'];
      }

      if ((isAdmin || isSupport) && !isImpersonating) {
        columns = ['impersonating', ...columns];
      }

      return columns;
    }),
  );

  constructor(private userNgrxService: UserNgrxService, private authNgrxService: AuthNgrxService, public dialog: MatDialog) {
    //
  }

  ngAfterViewInit() {
    combineLatest([
      this.userNgrxService.filters$,
      this.sort.sortChange.pipe(
        map(({ active, direction }) => (direction === 'desc' ? active : `-${active}`)),
        startWith(null),
      ),
    ])
      .pipe(
        map(([filters, order_by]) => ({ ...filters, order_by })),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(filters => this.userNgrxService.dispatchGetUsers(filters));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  impersonate(id) {
    this.userNgrxService.dispatchImpersonate(id);
  }

  userActiveStatus(data) {
    const dialogRef = this.dialog.open(UserActivateModalComponent, { data });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userNgrxService.dispatchChangeStatus({ ...data, active: !data.active });
      }
    });
  }
}
