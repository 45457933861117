import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { usersFilterReducer } from '@app/users/ngrx/reducers/users-filter.reducer';
import { usersReducer } from '@app/users/ngrx/reducers/users.reducer';
import { userNgrxModuleName } from '@app/users/ngrx/users-ngrx.const';

export interface UsersState {
  filter: any;
  users: any;
}

export const reducers: ActionReducerMap<UsersState> = {
  filter: usersFilterReducer,
  users: usersReducer,
};

export const getUsersState = createFeatureSelector<UsersState>(userNgrxModuleName);
