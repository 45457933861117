import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'login-credentials-form',
  templateUrl: './login-credentials-form.component.html',
  styleUrls: ['./login-credentials-form.component.scss'],
})
export class LoginCredentialsFormComponent implements OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject();
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  public loginForm = new FormGroup({
    username: new FormControl('', [validationCompose.email.rules]),
    password: new FormControl('', []),
  });

  constructor(public authNgrxService: AuthNgrxService) {
    this.authNgrxService.loginPending$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(loginPending => {
      loginPending ? this.loginForm.disable() : this.loginForm.enable();
    });
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }

    this.submitted.emit(this.loginForm.value);
    this.loginForm.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
