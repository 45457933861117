<div mat-dialog-title>
	<h3 class="modal-title">{{ 'pages.users.modals.user_active.title' | translate }}</h3>
</div>
<div mat-dialog-content>
	<h3>
		<p>
			<b>
				{{
					(
                        data.active
                            ? 'pages.users.modals.user_active.deactivate'
                            : 'pages.users.modals.user_active.activate'
					) | translate
				}}
			</b>
			{{ 'pages.users.modals.user_active.username' | translate }}:
		</p>
		<p>
			<i>{{ data.username }}</i>?
		</p>
	</h3>
</div>
<div mat-dialog-actions class="actions">
	<button mat-button class="btn btn-primary" [mat-dialog-close]="true">
		{{ 'pages.users.modals.user_active.ok' | translate }}
	</button>
	<button mat-button [mat-dialog-close]="false" class="btn btn-warning">
		{{ 'pages.users.modals.user_active.cancel' | translate }}
	</button>
</div>
