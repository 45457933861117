<div class="migration">
  Attention : The pcAmerica On Cloud Portal will be shut down on May 7th, 2025. We encourage you to consider exploring Heartland Mobile Manager as a replacement for the On Cloud Portal. If you have any questions, please contact your <a href="mailto:dam@heartland.us">dealer account manager</a> or your dealer sales representative.
</div>
<app-toolbar (burgerClicked)="drawerTrigger$.next(true)" [version]="version"></app-toolbar>
<mat-drawer-container class="mat-accent" autosize>
    <mat-drawer-content>
        <app-sidebar [drawerTrigger]="drawerTrigger$"></app-sidebar>
        <div class="page-wrapper" [class.with-side]="isAuthorized$ | async" [ngClass.xs]="'xs'">
            <div class="content">
                <flash></flash>
                <router-outlet></router-outlet>
            </div>
			<app-footer></app-footer>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
