<ng-container *ngIf="!completed">
	<h2>{{ 'pages.reset_request.title' | translate }}</h2>
	<p>{{ 'pages.reset_request.subtitle' | translate }}</p>
	<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
		<div class="form-group">
			<div class="input-group">
				<div class="icon">
					<mat-icon>mail</mat-icon>
				</div>
				<input placeholder="{{ 'pages.reset_request.form.email' | translate }}"
				       tabindex="1"
				       appWithErrorView
				       type="text"
				       autocomplete="email"
				       formControlName="email"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<button mat-raised-button class="btn btn-primary" type="submit" [disabled]="!resetPasswordForm.valid">
				{{ 'pages.reset_request.form.submit' | translate }}
			</button>
		</div>
	</form>
</ng-container>

<static-alert *ngIf="completed"
              title="pages.reset_request.completed.title"
              message="pages.reset_request.completed.message"
              [params]="resetPasswordForm.value"></static-alert>

<auth-footer place="reset-request"></auth-footer>

