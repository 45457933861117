import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { userNgrxModuleName } from '@app/users/ngrx/users-ngrx.const';
import { accountNgrxModuleName } from '@app/account/ngrx/account-ngrx.const';
import { authNgrxModuleName } from '@app/auth/ngrx/auth-ngrx.const';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [authNgrxModuleName, accountNgrxModuleName, userNgrxModuleName],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
