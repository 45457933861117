import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';

@Component({
  selector: 'login-passcode-form',
  templateUrl: './login-passcode-form.component.html',
  styleUrls: ['./login-passcode-form.component.scss'],
})
export class LoginPasscodeFormComponent implements OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject();
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  public trigger$ = new Subject();
  public delay = 30;
  public isCodeResentDebounce$ = this.trigger$.pipe(
    startWith(this.delay),
    switchMap(() =>
      timer(0, 1000).pipe(
        take(this.delay + 1),
        map(i => this.delay - i),
      ),
    ),
  );
  public codeForm = new FormGroup({
    passCode: new FormControl(null, [Validators.required]),
  });

  constructor(public authNgrxService: AuthNgrxService) {
    this.authNgrxService.loginPending$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(loginPending => {
      loginPending ? this.codeForm.disable() : this.codeForm.enable();
    });
  }

  public onSubmit() {
    this.trigger$.next(true);
    this.submitted.emit(this.codeForm.value);
    this.codeForm.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
