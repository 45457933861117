import { dashboardActions } from '@app/dashboard/ngrx/actions';
import { Action } from '@ngrx/store';
import update from 'immutability-helper';

export const defaultState: any = {
  loading: false,
  graphs: null,
  error: null,
};

export function dashboardReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case dashboardActions.getGraphs.GET_GRAPHS: {
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case dashboardActions.getGraphs.GET_GRAPHS_COMPLETED: {
      return update(state, {
        graphs: { $set: (action as any).payload },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case dashboardActions.getGraphs.GET_GRAPHS_FAILED: {
      return update(state, {
        graphs: { $set: null },
        loading: { $set: false },
        error: { $set: (action as any).payload },
      });
    }

    case dashboardActions.getGraph.GET_GRAPH: {
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case dashboardActions.getGraph.GET_GRAPH_COMPLETED: {
      return update(state, {
        graphs: { $merge: (action as any).payload },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case dashboardActions.getGraph.GET_GRAPH_FAILED: {
      return update(state, {
        graphs: { $set: null },
        loading: { $set: false },
        error: { $set: (action as any).payload },
      });
    }

    default:
      return state;
  }
}
