import { NgModule } from '@angular/core';

import { UsersRoutingModule } from '@app/users/users.routing.module';
import { UsersPageComponent } from '@app/users/pages/users/users.page.component';
import { UserProvider } from '@app/users/services/user.provider';
import { UsersFilterComponent } from '@app/users/components/users-filter/users-filter.component';
import { SharedModule } from '@app/shared/shared.module';
import { UsersNgrxModule } from '@app/users/ngrx/users-ngrx.module';
import { UserNgrxService } from '@app/users/services/user-ngrx.service';
import { UserActivateModalComponent } from '@app/users/modals/user-activate/user-activate.modal.component';
import { UserService } from '@app/users/services/user.service';
import { UserRolesPipe } from '@app/users/pipes/user-roles.pipe';

@NgModule({
  declarations: [UsersPageComponent, UsersFilterComponent, UserActivateModalComponent, UserRolesPipe],
  imports: [SharedModule, UsersRoutingModule, UsersNgrxModule],
  providers: [UserProvider, UserNgrxService, UserService],
})
export class UsersModule {
  //
}
