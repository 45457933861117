<ng-container
    *ngIf='!messageText && appFormControl && hasError'
    [ngSwitch]='true'
>
    <small
        *ngSwitchCase='appFormControl.errors.required'
        class="d-block invalid-feedback">
        {{ 'form.validation.required' | translate }}
    </small>
    <!--        {{ 'form.validation.emailExist' | translate:{ 'emailExist': appFormControl.errors.emailExist } }}-->


    <small
        *ngSwitchCase='appFormControl.errors.minlength && appFormControl.errors.minlength.actualLength <= appFormControl.errors.minlength.requiredLength'
        class="d-block invalid-feedback">
        {{ 'form.validation.minlength' | translate: appErrorParams.minlength || { min: appFormControl.errors.minlength.requiredLength } }}
    </small>

    <small
        *ngSwitchCase='appFormControl.errors.maxlength && appFormControl.errors.maxlength.actualLength > appFormControl.errors.maxlength.requiredLength'
        class="d-block invalid-feedback">
        {{ 'form.validation.maxlength' | translate:appErrorParams.maxlength || { max: appFormControl.errors.maxlength.requiredLength } }}
    </small>

    <small
        *ngSwitchCase='appFormControl.errors.email || appFormControl.errors.invalid_email'
        class="d-block invalid-feedback">
        {{ 'form.validation.email' | translate }}
    </small>

    <small
            *ngSwitchCase='appFormControl.errors.password'
            class="d-block invalid-feedback">
        {{ 'form.validation.password' | translate }}
    </small>

    <small
        *ngSwitchCase='appFormControl.errors.passwords_not_match'
        class="d-block invalid-feedback">
        {{ 'form.validation.passwords_not_match' | translate }}
    </small>

    <ng-container *ngSwitchDefault></ng-container>
</ng-container>

<small
    *ngIf='appFormControl && appFormControl.errors.server_error'
    class="d-block invalid-feedback">
    {{ appFormControl.errors.server_error }}
</small>

<small *ngIf='messageText && appFormControl && hasError' class="d-block invalid-feedback">
    {{ messageText | translate:messageParams }}
</small>

<small *ngIf='messageText && !appFormControl' class="d-block invalid-feedback">
    {{ messageText | translate:messageParams }}
</small>
