import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Flesh } from '@app/core/types/flesh';

@Injectable()
export class FleshService {
  flesh$: BehaviorSubject<Flesh.IFlesh> = new BehaviorSubject(null);

  public dispatch(flesh: Flesh.IFlesh) {
    this.flesh$.next(flesh);
  }

  public dispatchClear() {
    this.flesh$.next(null);
  }
}
