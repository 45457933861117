import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent {
  @Input() type = 'success';
  @Input() title: string;
  @Input() message: string;
  @Input() params: any;
  @Input() action = null;
  @Output() actionClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
}
