import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { usersActions } from '@app/users/ngrx/actions';

export const defaultState: any = {
  loading: false,
  users: [],
  error: null,
};

export function usersReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case usersActions.getUsers.GET_USERS: {
      return update(state, {
        users: { $set: [] },
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case usersActions.getUsers.GET_USERS_COMPLETED: {
      const users = (action as any).payload?.users;
      return update(state, {
        users: { $set: users || [] },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case usersActions.getUsers.GET_USERS_FAILED: {
      return update(state, {
        users: { $set: [] },
        loading: { $set: false },
        error: { $set: (action as any).payload },
      });
    }

    default:
      return state;
  }
}
