import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConst } from '@app/core/constants/url.const';
import { URLService } from '@app/core/services/url.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardProvider {
  constructor(private http: HttpClient, private urlService: URLService) {}

  getGraphs({ id, params }) {
    return this.http.get(this.urlService.generate(UrlConst.DASHBOARD, { id }), { params });
  }

  getGraph({ id, params }) {
    return this.http
      .get(this.urlService.generate(UrlConst.DASHBOARD, { id }), { params })
      .pipe(map(data => data[params.type.split('_')[0]]));
  }
}
