import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/users/ngrx/reducers';
import { UsersState } from '@app/users/ngrx/reducers';

export const getUsersState = createSelector(fromFeature.getUsersState, (state: UsersState) => state.users);

export const getUsersFilterState = createSelector(fromFeature.getUsersState, (state: UsersState) => state.filter);

export const getFilters = createSelector(getUsersFilterState, (state: any) => state.filter);

export const getUsers = createSelector(getUsersState, (state: any) => state.users);
