import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { authActions } from '@app/auth/ngrx/actions';

export const defaultState: any = {
  errors: null,
  loading: false,
  completed: false,
};

export function activationReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case authActions.requestToken.REQUEST_TOKEN_RESET: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: false },
        completed: { $set: false },
      });
    }

    case authActions.requestToken.REQUEST_TOKEN: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: true },
        completed: { $set: false },
      });
    }

    case authActions.requestToken.REQUEST_TOKEN_COMPLETED: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: false },
        completed: { $set: true },
      });
    }

    case authActions.requestToken.REQUEST_TOKEN_FAILED: {
      const {
        error: { errors },
      } = (action as any).payload;
      return update(state, {
        errors: { $set: errors },
        loading: { $set: false },
        completed: { $set: false },
      });
    }

    default:
      return state;
  }
}
