import { NgModule } from '@angular/core';

import { DashboardRoutingModule } from '@app/dashboard/dashboard.routing.module';
import { DashboardNgrxModule } from '@app/dashboard/ngrx/dashboard-ngrx.module';
import { DashboardGraphItemComponent } from '@app/dashboard/pages/dashboard-graph-item/dashboard-graph-item.component';
import { DashboardPageComponent } from '@app/dashboard/pages/dashboard/dashboard.page.component';
import { DashboardGraphsService } from '@app/dashboard/services/dashboard-graphs.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardNgrxService } from '@app/dashboard/services/dashboard-ngrx.service';
import { DashboardProvider } from '@app/dashboard/services/dashboard.provider';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from '@angular/common';
import { SummaryInfoComponent } from './components/summary-info/summary-info.component';

@NgModule({
  declarations: [DashboardPageComponent, DashboardGraphItemComponent, SummaryInfoComponent],
  imports: [CommonModule, DashboardRoutingModule, TranslateModule, SharedModule, DashboardNgrxModule],
  providers: [DashboardService, DashboardProvider, DashboardNgrxService, DashboardGraphsService],
})
export class DashboardModule {}
