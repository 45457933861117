import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'dashboard-graph-item',
  templateUrl: './dashboard-graph-item.component.html',
  styleUrls: ['./dashboard-graph-item.component.scss'],
})
export class DashboardGraphItemComponent {
  @Input() maxDay: moment.Moment;
  @Input() type: string;

  date = new FormControl(new Date());
}
