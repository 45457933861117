import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { authActions } from '@app/auth/ngrx/actions';

export const defaultState: any = {
  errors: null,
  loading: false,
  valid: false,
};

export function registerCheckReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case authActions.register.REGISTER_RESET: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: false },
        valid: { $set: false },
      });
    }

    case authActions.checkRegister.CHECK_REGISTER: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: true },
        valid: { $set: false },
      });
    }

    case authActions.checkRegister.CHECK_REGISTER_COMPLETED: {
      return update(state, {
        errors: { $set: null },
        loading: { $set: false },
        valid: { $set: true },
      });
    }

    case authActions.checkRegister.CHECK_REGISTER_FAILED: {
      const {
        error: { errors },
      } = (action as any).payload;

      return update(state, {
        errors: { $set: errors },
        loading: { $set: false },
        valid: { $set: false },
      });
    }

    default:
      return state;
  }
}
