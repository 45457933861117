import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';
import { Chart, registerables } from 'chart.js';
import { formatCurrency } from "@angular/common";

@Component({
  selector: 'doughnut-graph',
  templateUrl: './doughnut-graph.component.html',
  styleUrls: ['./doughnut-graph.component.scss'],
})
export class DoughnutGraphComponent {
  @Input() set sets(data: Dashboard.IGraphData) {
    this._data = data;
    if (this._data && !this.chart) {
      this.draw(this._data);
    } else if (this._data) {
      this.update(this._data);
    }
  }

  @ViewChild('doughnutGraphCanvas', { static: true }) private doughnutGraphCanvas: ElementRef;
  public chart;
  public _data;
  // dataset index of last active/hover element
  public lastActiveIndex = 0;

  constructor() {
    Chart.register(...registerables);
  }

  draw(data) {
    this.chart = new Chart(this.doughnutGraphCanvas.nativeElement, {
      type: 'doughnut',
      data: { labels: data?.labels, datasets: data?.datasets },
      plugins: [
        // @TODO: border inside when section is active
        // {
        //     id: 'highlightActiveElement',
        //     beforeDraw: (chart, args, opts) => {
        //         const {
        //             ctx,
        //         } = chart;
        //         const arcs = chart.getDatasetMeta(0).data;
        //         const width = 2;
        //         let color = '#000'
        //
        //         if (chart['_active'].length > 0) {
        //             color = chart.config.data.datasets[chart['_active'][0].datasetIndex].backgroundColor[chart['_active'][0].index];
        //         }
        //
        //         ctx.lineWidth = width;
        //         ctx.strokeStyle = color;
        //
        //         arcs.forEach(a => {
        //             ctx.beginPath();
        //             ctx.arc(a.x, a.y, a['innerRadius'] + width / 2, a['startAngle'], a['endAngle']);
        //             ctx.stroke();
        //         });
        //     },
        // },
        {
          id: 'activeDefaultElement',
          afterUpdate(chart: Chart) {
            chart.setActiveElements([{ datasetIndex: 0, index: 0 }]);
          },
        },
        {
          id: 'showLabel',
          afterDraw(chart: Chart) {
            const {
              ctx,
              chartArea: { width, height },
            } = chart;
            ctx.save();

            const wrapText = (context, text, x, y) => {
              const words = text.split('\n');
              const lineHeight = 30;
              for (let i = 0; i < words.length; i++) {
                context.fillText(words[i], x, y + i * lineHeight);
              }
            };

            if (chart.getActiveElements().length === 0) {
              chart.setActiveElements([{ datasetIndex: 0, index: this.lastActiveIndex }]);
            }

            if (chart.getActiveElements().length > 0) {
              this.lastActiveIndex = chart.getActiveElements()[0].index;
              const textLabel = chart.config.data.labels[chart.getActiveElements()[0].index];
              const numberLabel =
                chart.config.data.datasets[chart.getActiveElements()[0].datasetIndex].data[chart.getActiveElements()[0].index];

              ctx.font = 'bolder 30px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              wrapText(ctx, `${textLabel}\n${formatCurrency(numberLabel as number, 'en-US', '$')}`, width / 2, height / 2);
            }
            ctx.restore();
          },
        },
      ],
      options: {
        cutout: 110,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 10,
            top: 10,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }

  update(sets) {
    this.chart.data.labels = sets?.labels;
    this.chart.data.datasets = sets?.datasets;
    this.chart.setActiveElements([]);
    this.chart.update('none');
  }
}
