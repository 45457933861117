import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ToastsEffect } from '@app/shared/modules/toasts/ngrx/effects/toasts.effect';

@NgModule({
  imports: [EffectsModule.forFeature([ToastsEffect])],
})
export class ToastsNgrxModule {
  //
}
