<div mat-dialog-title>
	<h3 class="modal-title">{{ 'pages.account.modals.profile.title' | translate }}</h3>
	<p>
		{{ 'pages.account.modals.profile.review' | translate }}
		<a href="" (click)="showTerms()">
			{{ 'pages.account.modals.profile.terms' | translate }}
		</a>
	</p>
</div>
<div mat-dialog-content>
	<form [formGroup]="personalInfoForm">
		<h4>{{ 'pages.account.modals.profile.personal_info' | translate }}</h4>
		<div class="form-group">
			<div class="input-group">
				<input placeholder="{{ 'pages.account.modals.profile.first_name' | translate }}"
				       appWithErrorView="firstName"
				       tabindex="1"
				       type="text"
				       formControlName="first_name"
				       class="form-control">

			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<input placeholder="{{ 'pages.account.modals.profile.last_name' | translate }}"
				       appWithErrorView="lastName"
				       tabindex="2"
				       type="text"
				       formControlName="last_name"
				       class="form-control">
			</div>
		</div>
	</form>

	<ng-container *ngIf="!(isImpersonated$ | async)">
		<h4>{{ 'pages.account.modals.profile.change_password' | translate }}</h4>
		<form [formGroup]="passwordsForm">
			<div class="form-group">
				<div class="input-group">
					<input placeholder="{{ 'pages.account.modals.profile.password' | translate }}"
					       appWithErrorView="password"
					       tabindex="3"
					       autocomplete="new-password"
					       type="password"
					       formControlName="old_password"
					       class="form-control">
				</div>
			</div>
			<div class="form-group">
				<div class="input-group">
					<input placeholder="{{ 'pages.account.modals.profile.new_password' | translate }}"
					       appWithErrorView="password"
					       tabindex="4"
					       autocomplete="new-password"
					       type="password"
					       formControlName="new_password"
					       class="form-control">
				</div>
			</div>
			<div class="form-group">
				<div class="input-group">
					<input placeholder="{{ 'pages.account.modals.profile.confirm_password' | translate }}"
					       appWithErrorView="password"
					       tabindex="5"
					       autocomplete="new-password"
					       type="password"
					       formControlName="new_password_confirm"
					       class="form-control">
				</div>
				<app-error-feedback
						*ngIf="passwordsForm.errors?.passwords_not_match"
						[appFormControl]='passwordsForm'
						[appErrorParams]="{}">
				</app-error-feedback>
			</div>
		</form>
	</ng-container>
</div>

<div mat-dialog-actions class="actions">
	<button mat-button
	        class="btn btn-primary"
	        [mat-dialog-close]="response"
	        [disabled]="!personalInfoForm.dirty && !passwordsForm.dirty">
		{{ 'pages.account.modals.profile.ok' | translate }}
	</button>
	<button mat-button [mat-dialog-close]="false" class="btn btn-warning">
		{{ 'pages.account.modals.profile.cancel' | translate }}
	</button>
</div>
