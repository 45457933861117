import { Component, Input } from '@angular/core';
import { appRoutingNames } from '@app/app-routing-names';
import { authRoutingNames } from '@app/auth/auth-routing-names';

@Component({
  selector: 'auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss'],
})
export class AuthFooterComponent {
  public links = {
    login: `/${appRoutingNames.AUTH}/${authRoutingNames.LOGIN}`,
    register: `/${appRoutingNames.AUTH}/${authRoutingNames.REGISTER}`,
    reset: `/${appRoutingNames.AUTH}/${authRoutingNames.RESET_REQUEST}`,
  };
  @Input() place: string;
}
