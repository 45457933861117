import { Component, Input } from '@angular/core';
import { Errors } from '@app/core/types/errors';

@Component({
  selector: 'general-modal-error',
  templateUrl: './general-modal-error.component.html',
  styleUrls: ['./general-modal-error.component.scss'],
})
export class GeneralModalErrorComponent {
  @Input() set generalErrors(data: Errors.IError[]) {
    if (data) {
      this.errors = data.filter(err => err.location === Errors.EType.GENERAL);
    }
  }
  public errors: Errors.IError[] = [];
}
