import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, tap } from 'rxjs';
import { catchError, filter, pluck, switchMap } from 'rxjs/operators';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { UserProvider } from '@app/users/services/user.provider';
import { accountActions } from '@app/account/ngrx/actions';
import { AccountProvider } from '@app/account/services/account.provider';
import { AccountService } from '@app/account/services/account.service';
import { CurrentUser } from '@app/core/types/current-user';

@Injectable()
export class AccountEffect {
  constructor(
    private actions$: Actions,
    private userProvider: UserProvider,
    private authNgrxService: AuthNgrxService,
    private accountProvider: AccountProvider,
    private accountService: AccountService,
  ) {
    //
  }

  getAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.getAccount.GET_ACCOUNT),
      switchMap(({ payload: { id } }) =>
        this.userProvider.getById(id).pipe(
          switchMap(user => of(new accountActions.getAccount.GetAccountCompletedAction(user))),
          catchError(error => of(new accountActions.getAccount.GetAccountFailedAction(error))),
        ),
      ),
    );
  });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.updateAccount.UPDATE_ACCOUNT),
      switchMap(({ payload: { info, id } }) =>
        this.userProvider.updateById(id, info).pipe(
          switchMap(user => of(new accountActions.updateAccount.UpdateAccountCompletedAction(user))),
          catchError(error => of(new accountActions.updateAccount.UpdateAccountFailedAction(error))),
        ),
      ),
    );
  });

  setSchema$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.setSchema.SET_SCHEMA),
      switchMap(({ payload }) =>
        this.accountProvider.setSchema(payload).pipe(
          switchMap(user => of(new accountActions.setSchema.SetSchemaCompletedAction(user))),
          catchError(error => of(new accountActions.setSchema.SetSchemaFailedAction(error))),
        ),
      ),
    );
  });

  getSchema$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.getSchema.GET_SCHEMA),
      switchMap(() =>
        this.accountProvider.getSchema().pipe(
          switchMap(schema => of(new accountActions.getSchema.GetSchemaCompletedAction(schema))),
          catchError(error => of(new accountActions.getSchema.GetSchemaFailedAction(error))),
        ),
      ),
    );
  });

  getSchemaCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountActions.getSchema.GET_SCHEMA_COMPLETED),
        filter(({ payload }) => payload.account.status === CurrentUser.EAccountStatus.new),
        switchMap(() => this.accountService.showFinalize()),
      );
    },
    { dispatch: false },
  );

  setSchemaCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(accountActions.setSchema.SET_SCHEMA_COMPLETED),
        filter(({ payload }) => payload.account.status === CurrentUser.EAccountStatus.ready),
        tap(() => this.authNgrxService.dispatchGetCurrentUser()),
        switchMap(data => of(data)),
      );
    },
    { dispatch: false },
  );
}
