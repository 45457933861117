import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { sessionReducer } from '@app/auth/ngrx/reducers/auth.reducer';
import { Auth } from '@app/core/types/auth';
import { authNgrxModuleName } from '@app/auth/ngrx/auth-ngrx.const';
import { registerReducer } from '@app/auth/ngrx/reducers/register.reducer';
import { registerCheckReducer } from '@app/auth/ngrx/reducers/register-check.reducer';
import { activationReducer } from '@app/auth/ngrx/reducers/activation.reducer';

export interface AuthState {
  session: Auth.IAuthStoreState;
  register: any;
  registerCheck: any;
  activation: any;
}

export const reducers: ActionReducerMap<AuthState> = {
  session: sessionReducer,
  register: registerReducer,
  registerCheck: registerCheckReducer,
  activation: activationReducer,
};

export const getAuthState = createFeatureSelector<AuthState>(authNgrxModuleName);
