import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
})
export class LineGraphComponent {
  @Input() set sets(data: Dashboard.IGraphData) {
    this._data = data;
    if (this._data && !this.chart) {
      this.draw(this._data);
    } else if (this._data) {
      this.update(this._data);
    }
  }

  @ViewChild('lineGraphCanvas', { static: true }) private lineGraphCanvas: ElementRef;
  public chart;
  public _data;
  // dataset index of last active/hover element
  public lastActiveIndex = 0;

  constructor() {
    Chart.register(...registerables);
  }

  draw(data?) {
    this.chart = new Chart(this.lineGraphCanvas.nativeElement, {
      type: 'line',
      data: { labels: data?.labels, datasets: data?.datasets },
      plugins: [
        {
          id: 'updateTooltip',
          afterUpdate(chart: Chart) {
            const activeElements = [];
            const tooltip = chart.tooltip;
            const chartArea = chart.chartArea;
            if (tooltip.getActiveElements().length === 0 && chart.getActiveElements().length === 0) {
              new Array(chart.data.datasets.length).fill(null).forEach((dataset, index) => {
                activeElements.push({
                  datasetIndex: index,
                  index: chart.data.datasets[index].data.length - 1,
                });
              });
              chart.setActiveElements(activeElements);
              chart.tooltip.setActiveElements(activeElements, {
                x: (chartArea.left + chartArea.right) / 2,
                y: (chartArea.top + chartArea.bottom) / 2,
              });
            }
          },
        },
        {
          id: 'showTooltip',
          afterDraw(chart: Chart) {
            if (chart.getActiveElements().length > 0 && chart.getActiveElements()[0].index) {
              this.lastActiveIndex = chart.getActiveElements()[0].index;
            }
            if (chart.getActiveElements().length === 0) {
              const activeElements = [];
              const chartArea = chart.chartArea;
              new Array(chart.data.datasets.length).fill(null).forEach((dataset, index) => {
                const dataSet = chart.data.datasets[index]?.data || [];
                activeElements.push({
                  datasetIndex: index,
                  index: this.lastActiveIndex < dataSet.length ? this.lastActiveIndex : dataSet.length - 1,
                });
              });
              if (activeElements) {
                chart.setActiveElements(activeElements);
                chart.tooltip.setActiveElements(activeElements, {
                  x: (chartArea.left + chartArea.right) / 2,
                  y: (chartArea.top + chartArea.bottom) / 2,
                });
              }

            }
          },
        },
      ],
      options: {
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            titleAlign: 'center',
            yAlign: 'bottom',
            backgroundColor: '#fff',
            borderColor: 'rgba(230,230,230,0.8)',
            padding: 6,
            displayColors: false,
            bodyColor: '#666',
            titleColor: '#666',
            borderWidth: 2,
            cornerRadius: 10,
            bodyFont: { weight: 'bold' },
            callbacks: {
              labelTextColor: context => {
                return context.dataset.backgroundColor;
              },
              ...data?.callbacks,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      },
    });
  }

  update(sets) {
    this.chart.data.labels = sets?.labels;
    this.chart.data.datasets = sets?.datasets;
    if (sets?.callbacks) {
      this.chart.options.plugins.tooltip.callbacks = {
        ...this.chart.options.plugins.tooltip.callbacks,
        ...sets?.callbacks,
      };
    }
    this.chart.tooltip.setActiveElements([]);
    this.chart.setActiveElements([]);
    this.chart.update('none');
  }
}
