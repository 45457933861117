import { NgModule } from '@angular/core';

import { reducers } from '@app/account/ngrx/reducers';
import { effects } from '@app/account/ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { accountNgrxModuleName } from '@app/account/ngrx/account-ngrx.const';

@NgModule({
  imports: [StoreModule.forFeature(accountNgrxModuleName, reducers), EffectsModule.forFeature(effects)],
})
export class AccountNgrxModule {
  //
}
