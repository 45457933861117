import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input() optionsItem;
  @Output() onSelectData = new EventEmitter();
  public value = '';
  public ngControl: NgControl;

  constructor(private inj: Injector) {
    //
  }

  ngOnInit() {
    this.ngControl = this.inj.get(NgControl);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(_: any) {
    //
  }

  writeValue(value) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {
    //
  }

  onValueChange({ value }) {
    this.writeValue(value);
    this.onSelectData.emit(value);
  }
}
