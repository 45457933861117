import { Injectable } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Dashboard } from '@app/core/types/dashboard';
import { Dates } from '@app/core/types/dates';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
  public isToday$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public options$: BehaviorSubject<any> = new BehaviorSubject(null);
  public formFilters = this.fb.group({
    date: this.fb.control(null, []),
    hourly: this.fb.control(null, []),
    hourly_range: this.fb.control(null, []),
    gross: this.fb.control(null, []),
    net: this.fb.control(null, []),
    todate: this.fb.control(null, []),
  });

  public get getDate() {
    return this.formFilters.get('date') as FormControl;
  }

  public store$ = this.authNgrxService.userStore$.pipe(map(store => store?.id));

  constructor(private fb: FormBuilder, private translate: TranslateService, private authNgrxService: AuthNgrxService) {}

  public generateOptions(isToday, date) {
    const todayTranslation = this.translate.instant('pages.dashboard.range.today');
    const selectedDay = isToday ? todayTranslation : date;

    const hourlyOptions = [
      { value: Dashboard.ESalesType.AMOUNT, name: this.translate.instant('pages.dashboard.graphs.hourly_sales.net_sales') },
      { value: Dashboard.ESalesType.ITEMS_SOLD, name: this.translate.instant('pages.dashboard.graphs.hourly_sales.item_sales') },
      {
        value: Dashboard.ESalesType.TRANSACTIONS,
        name: this.translate.instant('pages.dashboard.graphs.hourly_sales.transaction'),
      },
    ];
    const hourlyRange = [
      { value: Dashboard.ESalesType.ALL_DAY, name: this.translate.instant('pages.dashboard.range.all_day') },
      { value: Dashboard.ESalesType.NIGHT_TIME, name: '12:00AM-8:00AM' },
      { value: Dashboard.ESalesType.DAY_TIME, name: '8:00AM-4:00PM' },
      { value: Dashboard.ESalesType.EVENING_TIME, name: '4:00PM-12:00AM' },
    ];
    const grossOptions = [
      {
        value: Dashboard.ESalesType.TODAY,
        name: this.translate.instant('pages.dashboard.graphs.gross.to_date', { date: selectedDay }),
      },
      { value: Dashboard.ESalesType.LAST_7, name: this.translate.instant('pages.dashboard.graphs.gross.7_day') },
      { value: Dashboard.ESalesType.LAST_14, name: this.translate.instant('pages.dashboard.graphs.gross.14_day') },
      { value: Dashboard.ESalesType.LAST_30, name: this.translate.instant('pages.dashboard.graphs.gross.30_day') },
    ];
    const netSalesOptions = [
      {
        value: Dashboard.ESalesType.TODAY,
        name: this.translate.instant('pages.dashboard.graphs.net_sales.to_date', { date: selectedDay }),
      },
      { value: Dashboard.ESalesType.LAST_7, name: this.translate.instant('pages.dashboard.graphs.net_sales.7_day') },
      { value: Dashboard.ESalesType.LAST_14, name: this.translate.instant('pages.dashboard.graphs.net_sales.14_day') },
      { value: Dashboard.ESalesType.LAST_30, name: this.translate.instant('pages.dashboard.graphs.net_sales.30_day') },
    ];
    const toDateOptions = [
      {
        value: Dashboard.ESalesType.WEEK,
        name: this.translate.instant('pages.dashboard.graphs.to_date_sales.week', { date: selectedDay }),
      },
      {
        value: Dashboard.ESalesType.MONTH,
        name: this.translate.instant('pages.dashboard.graphs.to_date_sales.month', { date: selectedDay }),
      },
      {
        value: Dashboard.ESalesType.YEAR,
        name: this.translate.instant('pages.dashboard.graphs.to_date_sales.year', { date: selectedDay }),
      },
    ];
    return {
      hourlyOptions,
      hourlyRange,
      grossOptions,
      netSalesOptions,
      toDateOptions,
    };
  }

  public updateFormFilters(data = {}) {
    this.formFilters.patchValue(data);
    this.checkSelectedDay(this.getDate.value);
  }

  public checkSelectedDay(date) {
    const is_today = !moment().diff(date, 'day');
    const options = this.generateOptions(is_today, moment(date).format(Dates.formats.date));
    this.isToday$.next(is_today);
    this.options$.next(options);
  }
}
