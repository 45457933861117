<input class="hide-input" matInput
       [matDatepicker]="datepicker"
       [(ngModel)]="dpValue"
       [max]="maxDate"
       (dateInput)="onValueChange($event)"
>
<div class="title" (click)="openDatepicker()">
    {{ (_options.isToday ? 'pages.dashboard.graphs.all_sales_today' : 'pages.dashboard.graphs.all_sales') | translate | uppercase}}
    ({{value | date: 'MM/dd/YYYY'}}
</div>
<mat-icon>calendar_month</mat-icon>
)
<mat-datepicker #datepicker></mat-datepicker>

<!-- Workaround for adding the buttons -->
<div style="display: none">
    <div class="datepicker-footer" #datepickerFooter style="padding-bottom: 10px;text-align: center">
        <button mat-raised-button color="primary" (click)="setToday(datepicker)">
            {{'pages.dashboard.today-btn' | translate}}
        </button>
    </div>
</div>
