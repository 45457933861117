export namespace Dates {
  export interface IFormatsConst {
    date: string;
    dateShort: string;
    serverDate: string;
    dateTimeLocalSeconds: string;
    serverTime: string;
  }

  export const formats: IFormatsConst = {
    date: 'MM/DD/YYYY',
    dateShort: 'MM/DD/YY',
    serverDate: 'YYYY-MM-DD',
    dateTimeLocalSeconds: 'MM/DD/YYYY @ h:mm:ss A Z',
    serverTime: 'HH:mm',
  };
}
