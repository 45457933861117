import { NgModule } from '@angular/core';
import { ToolbarComponent } from '@app/layout/components/toolbar/toolbar.component';
import { SidebarComponent } from '@app/layout/components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { AccountModule } from '@app/account/account.module';
import { FooterComponent } from '@app/layout/components/footer/footer.component';
import { SharedModule } from '@app/shared/shared.module';
import { FleshComponent } from '@app/layout/components/static-message/flesh.component';
import { FleshService } from '@app/layout/services/flesh.service';

@NgModule({
  imports: [AccountModule, RouterModule, SharedModule],
  exports: [ToolbarComponent, SidebarComponent, FooterComponent, FleshComponent],
  declarations: [ToolbarComponent, SidebarComponent, FooterComponent, FleshComponent],
  providers: [FleshService],
})
export class AppLayoutModule {}
