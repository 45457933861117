import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { reducers } from '@app/core/store/reducers';
import { metaReducers } from '@app/core/store/reducers/meta-reducers';
import { CustomSerializer } from '@app/core/store/reducers/route.reducer';

import environment from '@env';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
  declarations: [],
})
export class AppNgrxModule {}
