<ng-container *ngIf="!processing">
	<h1 style="text-align: center;">{{ 'pages.account.modals.account_finalize.title' | translate }}</h1>
	<callout type="warning"
	         title="pages.account.modals.account_finalize.note.title"
	         message="pages.account.modals.account_finalize.note.message"></callout>
	<form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
		<div class="form-group">
			<label>{{ 'pages.account.modals.account_finalize.form.company_name' | translate }}</label>

			<div class="input-group">
				<input placeholder="{{ 'pages.account.modals.account_finalize.form.company_name_placeholder' | translate }}"
				       tabindex="1"
				       appWithErrorView
				       type="text"
				       formControlName="company_name"
				       class="form-control">
			</div>
		</div>
		<div class="form-group">
			<button mat-raised-button class="btn btn-primary" type="submit"  [disabled]="!accountForm.valid">
				{{ 'pages.account.modals.account_finalize.form.submit' | translate }}
			</button>
		</div>
	</form>
</ng-container>

<static-alert *ngIf="processing"
		type="progress"
		title="pages.account.modals.account_finalize.alert.title"
		message="pages.account.modals.account_finalize.alert.message"></static-alert>

