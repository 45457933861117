import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/account/ngrx/reducers';
import { AccountState } from '@app/account/ngrx/reducers';

export const getCompleteCoreState = createSelector(fromFeature.getAccountState, (state: AccountState) => state.account);

export const getPending = createSelector(getCompleteCoreState, (state: any) => state.loading);

export const getAccountInfo = createSelector(getCompleteCoreState, (state: any) => state.user);
