import { IActions, setAction } from '@app/core/store/actions.generator';
import { userNgrxModuleName } from '@app/users/ngrx/users-ngrx.const';

export const usersActions: {
  setUsersFilter: IActions;
  getUsers: IActions;
  updateUser: IActions;
  impersonate: IActions;
  offImpersonate: IActions;
} = {
  setUsersFilter: setAction(userNgrxModuleName, ['Set', 'Users', 'Filter']),
  getUsers: setAction(userNgrxModuleName, ['Get', 'Users']),
  updateUser: setAction(userNgrxModuleName, ['Update', 'User']),
  impersonate: setAction(userNgrxModuleName, ['Impersonate']),
  offImpersonate: setAction(userNgrxModuleName, ['Off', 'Impersonate']),
};
