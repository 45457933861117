import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false }) public drawer: MatSidenav;
  @Input() drawerTrigger: Subject<boolean>;
  private ngUnsubscribe$: Subject<void> = new Subject();
  public isAuthorized$ = this.authService.isAuthorized$;
  public closed = true;
  private onEndNav$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.drawerTrigger.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.closed = !this.closed));
    this.onEndNav$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.closed = true));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
