import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss'],
})
export class BarGraphComponent {
  @Input() set sets(data: Dashboard.IGraphData) {
    this._data = data;
    if (this._data && !this.chart) {
      this.draw(this._data);
    } else if (this._data) {
      this.update(this._data);
    }
  }

  @ViewChild('barGraphCanvas', { static: true }) private barGraphCanvas: ElementRef;
  public chart;
  public _data;

  constructor() {
    Chart.register(...registerables);
  }

  draw(data) {
    this.chart = new Chart(this.barGraphCanvas.nativeElement, {
      type: 'bar',
      data: { labels: data?.labels, datasets: data?.datasets },
      plugins: [
        {
          id: 'updateTooltip',
          afterUpdate(chart: Chart) {
            const activeElements = [];
            const chartArea = chart.chartArea;
            new Array(chart.data.datasets.length).fill(null).forEach((dataset, index) => {
              activeElements.push({
                datasetIndex: index,
                index: chart.data.datasets[index].data.length - 1,
              });
            });
            chart.tooltip.setActiveElements(activeElements, {
              x: (chartArea.left + chartArea.right) / 2,
              y: (chartArea.top + chartArea.bottom) / 2,
            });
          },
        },
      ],
      options: {
        events: [],
        elements: {
          line: {
            tension: 0.4,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            yAlign: 'bottom',
            titleAlign: 'center',
            backgroundColor: '#fff',
            borderColor: 'rgba(230,230,230,0.8)',
            padding: 6,
            displayColors: false,
            bodyColor: '#666',
            titleColor: '#666',
            borderWidth: 2,
            cornerRadius: 10,
            bodyFont: { weight: 'bold' },
            callbacks: {
              labelTextColor: context => {
                return context.dataset.backgroundColor;
              },
              ...data?.callbacks,
            },
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }

  update(sets) {
    this.chart.data.labels = sets?.labels;
    this.chart.data.datasets = sets?.datasets;
    if (sets?.callbacks) {
      this.chart.options.plugins.tooltip.callbacks = {
        ...this.chart.options.plugins.tooltip.callbacks,
        ...sets?.callbacks,
      };
    }
    this.chart.tooltip.setActiveElements([]);
    this.chart.update('none');
  }
}
