import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthProvider } from '@app/auth/services/auth.provider';
import { validationCompose } from '@app/core/validation/validation.compose';
import { first } from 'rxjs/operators';
import { matchPasswords } from '@app/core/validation/validators';
import { ActivatedRoute } from '@angular/router';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';

@Component({
  templateUrl: './reset-password.page.component.html',
  styleUrls: ['./reset-password.page.component.scss'],
})
export class ResetPasswordPageComponent {
  completed = false;
  resetPasswordForm = new FormGroup(
    {
      new_password: new FormControl('', [validationCompose.password.rules]),
      new_password_confirm: new FormControl('', [validationCompose.password.rules]),
      token: new FormControl('', []),
    },
    matchPasswords,
  );

  constructor(public authProvider: AuthProvider, private route: ActivatedRoute, private authNgrxService: AuthNgrxService) {
    this.route.params.pipe(first()).subscribe(({ token }) => {
      this.authNgrxService.dispatchValidateResetPassword({ token });
      this.resetPasswordForm.patchValue({ token });
    });
  }

  onSubmit() {
    if (!this.resetPasswordForm.valid) {
      return;
    }

    this.authProvider
      .resetPassword(this.resetPasswordForm.value)
      .pipe(first())
      .subscribe(() => (this.completed = true));
  }
}
