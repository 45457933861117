import { getLocaleCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';
import { Dates } from '@app/core/types/dates';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Injectable()
export class DashboardGraphsService {
  constructor(private translate: TranslateService) {}

  private getDataOfDayPart(data, hourly_range) {
    const allDayTimePart = [];
    let dayPart = [];
    data.forEach((item, index) => {
      allDayTimePart.push({
        time: moment({ hour: index }).format('h:mm A'),
        value: item,
      });
    });
    if (hourly_range === Dashboard.ESalesType.NIGHT_TIME) {
      dayPart = allDayTimePart.splice(0, 9);
      return dayPart;
    }
    if (hourly_range === Dashboard.ESalesType.DAY_TIME) {
      dayPart = allDayTimePart.splice(8, 9);
      return dayPart;
    }
    if (hourly_range === Dashboard.ESalesType.EVENING_TIME) {
      dayPart = allDayTimePart.splice(16, 9);
      return dayPart;
    }
    return allDayTimePart;
  }

  public hourlyModifyData({ date, hourly, hourly_range }, data: Dashboard.IHourly) {
    // return null if no data by selected filter
    if (!data[hourly].length) {
      return null;
    }

    const labels = this.getDataOfDayPart(data[hourly], hourly_range).map(i => i.time);
    const datasets = [
      {
        backgroundColor: 'rgb(255, 120, 87)',
        borderColor: 'rgb(255, 120, 87)',
        borderWidth: 2,
        hoverBorderWidth: 5,
        data: this.getDataOfDayPart(data[hourly], hourly_range).map(i => i.value),
      },
    ];
    const callbacks = {
      title: () => {
        return moment(date).format(Dates.formats.date);
      },
      afterTitle: tooltipItems => {
        return tooltipItems[0].label;
      },
      label: tooltipItem => {
        const type = this.translate.instant(`pages.dashboard.graphs.tooltip.${hourly}`);
        return `${type}: ${getLocaleCurrencySymbol('EN')}${tooltipItem.parsed.y.toFixed(2)}`;
      },
    };
    return { labels, datasets, callbacks };
  }

  private getDaysOfMonth(date) {
    const arrDays = [];
    new Array(date.length).fill(null).forEach((item, index) => {
      arrDays.push(moment({ date: index + 1 }).format(Dates.formats.date));
    });
    return arrDays;
  }

  private getMonthOfYears(date) {
    const arrMonth = [];
    new Array(date.length).fill(null).forEach((item, index) => {
      arrMonth.push(moment({ month: index }).format(Dates.formats.date));
    });
    return arrMonth;
  }

  public toDateModifyData({ todate }, data: Dashboard.ITodate) {
    // return null if no data by selected filter
    if (!data[todate].length) {
      return null;
    }
    let labels = [];
    const datasets = [
      {
        label: this.translate.instant('pages.dashboard.graphs.tooltip.this_year'),
        backgroundColor: 'rgb(66, 164, 187)',
        borderColor: 'rgb(66, 164, 187)',
        borderWidth: 2,
        hoverBorderWidth: 5,
        data: [],
      },
      {
        label: this.translate.instant('pages.dashboard.graphs.tooltip.last_year'),
        backgroundColor: 'rgb(255, 120, 87)',
        borderColor: 'rgb(255, 120, 87)',
        borderWidth: 2,
        hoverBorderWidth: 5,
        data: [],
      },
    ];

    if (todate === Dashboard.ESalesType.WEEK) {
      data[todate].forEach(item => {
        labels.push(moment(item.date).format(Dates.formats.date));
        datasets[0].data.push(item.this_year);
        datasets[1].data.push(item.last_year);
      });
    }
    if (todate === Dashboard.ESalesType.MONTH) {
      labels = this.getDaysOfMonth(data[todate]);
      data[todate].forEach(item => {
        datasets[0].data.push(item.this_year);
        datasets[1].data.push(item.last_year);
      });
    }
    if (todate === Dashboard.ESalesType.YEAR) {
      labels = this.getMonthOfYears(data[todate]);
      data[todate].forEach(item => {
        datasets[0].data.push(item.this_year);
        datasets[1].data.push(item.last_year);
      });
    }
    const callbacks = {
      label: tooltipItem => {
        return `${tooltipItem.dataset.label}: ${getLocaleCurrencySymbol('EN')}${tooltipItem.parsed.y.toFixed(2)}`;
      },
    };
    return { labels, datasets, callbacks };
  }

  public departmentModifyData(data: Dashboard.IDepartment) {
    // return null if no data
    if (!data.today.length) {
      return null;
    }
    const labels = [];
    const datasets = [
      {
        data: [],
        backgroundColor: [
          'rgb(8, 62, 103)',
          'rgb(16,84,138)',
          'rgb(22,95,152)',
          'rgb(13,102,169)',
          'rgb(30,121,192)',
          'rgb(32,147,232)',
          'rgb(76,165,234)',
          'rgb(111,134,208)',
          'rgb(114,125,196)',
          'rgb(57,94,189)',
        ],
        hoverOffset: 15,
        borderWidth: 2,
        hoverBorderWidth: 0,
      },
    ];
    data.today.forEach(item => {
      labels.push(item.label);
      datasets[0].data.push(item.value);
    });
    return { labels, datasets };
  }

  public grossModifyData({ gross }, data: Dashboard.IGrossDaysPrior) {
    // return null if no data
    if (Object.keys(data).length === 0 || Object.keys(data[gross]).length === 0) {
      return null;
    }
    let labels = [];
    const datasets = [
      {
        data: [],
        backgroundColor: 'rgb(41, 128, 185)',
        label: this.translate.instant('pages.dashboard.graphs.tooltip.gross_sales'),
      },
      {
        data: [],
        backgroundColor: 'rgb(39, 174, 96)',
        label: this.translate.instant('pages.dashboard.graphs.tooltip.total_cost'),
      },
      {
        data: [],
        backgroundColor: 'rgb(52, 73, 94)',
        label: this.translate.instant('pages.dashboard.graphs.tooltip.gross_profit'),
      },
    ];
    if (gross === Dashboard.ESalesType.TODAY) {
      labels = [moment(data.today.date).format(Dates.formats.date)];
      datasets[0].data = [data.today.sales];
      datasets[1].data = [data.today.cost];
      datasets[2].data = [data.today.profit];
    } else {
      labels = [`${moment(data[gross].date_start).format(Dates.formats.date)}-${moment(data[gross].date_end).format(Dates.formats.date)}`];
      datasets[0].data = [data[gross].sales];
      datasets[1].data = [data[gross].cost];
      datasets[2].data = [data[gross].profit];
    }
    const callbacks = {
      label: tooltipItem => {
        return `${tooltipItem.dataset.label}: ${getLocaleCurrencySymbol('EN')}${tooltipItem.parsed.y.toFixed(2)}`;
      },
    };
    return { labels, datasets, callbacks };
  }

  public netModifyData({ net }, data: Dashboard.INetDaysPrior) {
    // return null if no data
    if (Object.keys(data).length === 0 || Object.keys(data[net]).length === 0) {
      return null;
    }
    let labels = [];
    const datasets = [
      {
        data: [],
        backgroundColor: 'rgb(39, 174, 96)',
        label: this.translate.instant('pages.dashboard.graphs.tooltip.this_year'),
      },
      {
        data: [],
        backgroundColor: 'rgb(41, 128, 185)',
        label: this.translate.instant('pages.dashboard.graphs.tooltip.last_year'),
      },
    ];
    if (net === Dashboard.ESalesType.TODAY) {
      labels = [moment(data.today.date).format(Dates.formats.date)];
      datasets[0].data = [data.today.this_year];
      datasets[1].data = [data.today.last_year];
    } else {
      labels = [`${moment(data[net].date_start).format(Dates.formats.date)}-${moment(data[net].date_end).format(Dates.formats.date)}`];
      datasets[0].data = [data[net].this_year];
      datasets[1].data = [data[net].last_year];
    }
    const callbacks = {
      label: tooltipItem => {
        return `${tooltipItem.dataset.label}: ${getLocaleCurrencySymbol('EN')}${tooltipItem.parsed.y.toFixed(2)}`;
      },
    };
    return { labels, datasets, callbacks };
  }
}
