<mat-card>
	<form [formGroup]="filtersForm" (ngSubmit)="onSubmit()">
		<mat-card-title>
			<div fxLayout="column" fxLayout.gt-sm="row">
				<div fxLayout="row" fxLayout.xs="column">
					<div class="form-group">
						<div class="input-group">
							<input placeholder="{{ 'pages.users.components.users_filter.search' | translate }}"
							       tabindex="1"
							       type="text"
							       formControlName="search"
							       class="form-control">
						</div>
					</div>
					<div class="form-group">
						<button mat-raised-button class="btn btn-primary" type="submit"
						        [disabled]="!filtersForm.valid">
							{{ 'pages.users.components.users_filter.submit' | translate }}
						</button>
					</div>
				</div>
				<div fxLayout="row">
					<div class="form-group">
						<button mat-raised-button class="btn btn-secondary no-shadow" type="button"
						        (click)="isShowed = !isShowed">
							{{ ('pages.users.components.users_filter.' + (isShowed ? 'hide_filters' : 'show_filters')) | translate }}
							<mat-icon>keyboard_arrow_{{ isShowed ? 'up' : 'down'}}</mat-icon>
						</button>
					</div>
					<div class="form-group">
						<button mat-raised-button class="btn btn-default no-shadow" type="button"
						        (click)="filtersForm.reset()">
							{{ 'pages.users.components.users_filter.clear_filters' | translate }}
						</button>
					</div>
				</div>
			</div>
		</mat-card-title>

		<mat-card-content *ngIf="isShowed">
			<div fxLayout="column" fxLayout.xs="column">
				<div fxLayout="row" fxLayout.xs="column">
					<div class="form-group">
						<label>{{ 'pages.users.components.users_filter.username' | translate }}</label>
						<div class="input-group">
							<input tabindex="1"
							       type="text"
							       formControlName="username"
							       class="form-control">

						</div>
					</div>
					<div class="form-group">
						<label>{{ 'pages.users.components.users_filter.first' | translate }}</label>
						<div class="input-group">
							<input tabindex="1"
							       type="text"
							       formControlName="first_name"
							       class="form-control">

						</div>
					</div>
					<div class="form-group">
						<label>{{ 'pages.users.components.users_filter.last' | translate }}</label>
						<div class="input-group">
							<input tabindex="1"
							       type="text"
							       formControlName="last_name"
							       class="form-control">

						</div>
					</div>
				</div>
				<div fxLayout="row" fxLayout.xs="column">
					<div class="form-group">
						<label>{{ 'pages.users.components.users_filter.company' | translate }}</label>
						<div class="input-group">
							<input tabindex="1"
							       type="text"
							       formControlName="company_name"
							       class="form-control">

						</div>
					</div>
					<div class="form-group">
						<label>{{ 'pages.users.components.users_filter.status' | translate }}</label>
						<div class="input-group">
							<select  formControlName="active">
								<option value=""></option>
								<option value="true">{{ 'pages.users.components.users_filter.active' | translate }}</option>
								<option value="false">{{ 'pages.users.components.users_filter.inactive' | translate }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>
	</form>
</mat-card>
