import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/auth/services/auth.service';
import { appRoutingNames } from '@app/app-routing-names';

@Injectable()
export class UnAuthGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  protected guard(): Observable<boolean> {
    return this.authService.isAuthorized$.pipe(
      map(isAuthorized => {
        if (isAuthorized) {
          this.router.navigateByUrl(appRoutingNames.DASHBOARD);
        }
        return !isAuthorized;
      }),
    );
  }

  canLoad(): Observable<boolean> {
    return this.guard();
  }

  canActivate(): Observable<boolean> {
    return this.guard();
  }

  canActivateChild(): Observable<boolean> {
    return this.guard();
  }
}
