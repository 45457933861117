import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '@app/shared/modules/toasts/components/toast/toast.component';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Toast } from '@app/core/types/toast';

@Injectable()
export class ToastService {
  constructor(private matSnackBar: MatSnackBar, private translate: TranslateService) {
    //
  }

  process(messageId: string, type: Toast.EToastTypes, params?) {
    this.translate
      .get(messageId, params)
      .pipe(first())
      .subscribe(message => this.show(message, type));
  }

  show(message: string, type: Toast.EToastTypes) {
    this.matSnackBar.openFromComponent(ToastComponent, {
      horizontalPosition: 'end',
      duration: 3000,
      panelClass: type,
      data: { message, type },
    });
  }
}
