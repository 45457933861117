import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { matchPasswords } from '@app/core/validation/validators';
import { filter, switchMap } from 'rxjs/operators';
import { FormServerErrorsService } from '@app/shared/modules/forms/services/form-server-errors.service';
import { TermsService } from '@app/shared/modules/terms/services/terms.service';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { appRoutingNames } from "@app/app-routing-names";
import { authRoutingNames } from "@app/auth/auth-routing-names";

@Component({
  templateUrl: './register.page.component.html',
  styleUrls: ['./register.page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  public loginLink = `/${appRoutingNames.AUTH}/${authRoutingNames.LOGIN}`;
  public registerForm = new FormGroup(
    {
      first_name: new FormControl('', [validationCompose.firstName.rules]),
      last_name: new FormControl('', [validationCompose.lastName.rules]),
      email: new FormControl('', [validationCompose.email.rules]),
      username: new FormControl('', [validationCompose.username.rules]),
      password_confirm: new FormControl('', [validationCompose.password.rules]),
      password: new FormControl('', [validationCompose.password.rules]),
    },
    matchPasswords,
  );

  constructor(
    public termsService: TermsService,
    public formServerErrorsService: FormServerErrorsService,
    public authNgrxService: AuthNgrxService,
  ) {}

  ngOnInit() {
    this.authNgrxService.dispatchRegisterReset();

    this.authNgrxService.errors$
      .pipe(filter(errors => !!errors))
      .subscribe(errors => this.formServerErrorsService.applyErrors(this.registerForm, errors));
    this.authNgrxService.isValid$
      .pipe(
        filter(isValid => !!isValid),
        switchMap(() => this.termsService.showTerms(true)),
        filter(agree => agree),
      )
      .subscribe(() => this.authNgrxService.dispatchRegister(this.registerForm.value));
  }

  onSubmit() {
    if (!this.registerForm.valid) {
      return;
    }

    this.authNgrxService.dispatchCheckRegister(this.registerForm.value);
  }
}
