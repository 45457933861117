import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthProvider } from '@app/auth/services/auth.provider';
import { validationCompose } from '@app/core/validation/validation.compose';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './reset-password-request.page.component.html',
  styleUrls: ['./reset-password-request.page.component.scss'],
})
export class ResetPasswordRequestPageComponent {
  completed = false;
  resetPasswordForm = new FormGroup({
    email: new FormControl('', [validationCompose.email.rules]),
  });

  constructor(public authProvider: AuthProvider) {}

  onSubmit() {
    if (!this.resetPasswordForm.valid) {
      return;
    }

    this.authProvider
      .requestPasswordReset(this.resetPasswordForm.value)
      .pipe(first())
      .subscribe(() => (this.completed = true));
  }
}
