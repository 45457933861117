import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { Router } from '@angular/router';
import { appRoutingNames } from '@app/app-routing-names';
import { AccountNgrxService } from '@app/account/services/account-ngrx.service';

@Component({
  templateUrl: './user-activate.modal.component.html',
  styleUrls: ['./user-activate.modal.component.scss'],
})
export class UserActivateModalComponent {
  processing = false;
  accountForm = new FormGroup({
    company_name: new FormControl('', [validationCompose.company.rules]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserActivateModalComponent>,
    private accountNgrxService: AccountNgrxService,
    private router: Router,
  ) {
    //
  }

  onSubmit() {
    if (!this.accountForm.valid) {
      return;
    }

    this.processing = true;
    this.accountNgrxService.dispatchSetSchema(this.accountForm.value);
    this.dialogRef.close();
    return this.router.navigateByUrl(appRoutingNames.STORE);
  }
}
