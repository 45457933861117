import { IActions, setAction } from '@app/core/store/actions.generator';
import { authNgrxModuleName } from '@app/auth/ngrx/auth-ngrx.const';

export const authActions: {
  getCurrentUser: IActions;
  changePassword: IActions;
  checkRegister: IActions;
  register: IActions;
  login: IActions;
  setPhone: IActions;
  verifyPasscode: IActions;
  logout: IActions;
  activate: IActions;
  requestToken: IActions;
  validateResetPassword: IActions;
} = {
  getCurrentUser: setAction(authNgrxModuleName, ['Get', 'Current', 'User']),
  changePassword: setAction(authNgrxModuleName, ['Change', 'Password']),
  login: setAction(authNgrxModuleName, ['Login']),
  setPhone: setAction(authNgrxModuleName, ['Set', 'Phone']),
  verifyPasscode: setAction(authNgrxModuleName, ['Verify', 'PassCode']),
  checkRegister: setAction(authNgrxModuleName, ['Check', 'Register']),
  register: setAction(authNgrxModuleName, ['Register']),
  logout: setAction(authNgrxModuleName, ['Logout']),
  activate: setAction(authNgrxModuleName, ['Activate']),
  requestToken: setAction(authNgrxModuleName, ['Request', 'Token']),
  validateResetPassword: setAction(authNgrxModuleName, ['Validate', 'Reset', 'Password']),
};
