<div class="alert alert-{{ type }} text-center">
	<mat-progress-bar *ngIf="type=== 'progress'" mode="indeterminate"></mat-progress-bar>
	<h4>{{ title | translate }}</h4>
	<p class="text-center">{{ message | translate: params }}</p>
	<div class="divider"></div>

  <button *ngIf="redirect" mat-raised-button class="btn btn-primary" type="button" (click)="redirectTo(redirect)">
    {{ redirectName | translate }}
  </button>
</div>
