import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Dashboard } from '@app/core/types/dashboard';
import { Dates } from '@app/core/types/dates';
import moment from 'moment';

@Component({
  selector: 'custom-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
  public value = '';
  public dpValue = new Date();
  public ngControl: NgControl;
  public defaultOptions: Dashboard.IDatePickerOptions = {
    isToday: true,
  };
  public _options = { ...this.defaultOptions };
  public disabled = false;
  public maxDate = new Date();
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;
  @ViewChild('datepicker', { static: false }) datepicker: MatDatepicker<any>;

  @Output() onSelectData = new EventEmitter();

  @Input() set options(options: Dashboard.IDatePickerOptions) {
    this._options = {
      ...this._options,
      ...options,
    };
  }

  constructor(private inj: Injector) {
    //
  }

  ngOnInit() {
    this.ngControl = this.inj.get(NgControl);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(_: any) {
    //
  }

  writeValue(value) {
    this.value = moment(value).format(Dates.formats.serverDate);
    this.dpValue = moment(value).toDate();
    this.onChange(value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange({ value }) {
    this.writeValue(value);
    this.onSelectData.emit(value);
  }

  openDatepicker() {
    this.datepicker.open();
    this.appendFooter();
  }

  appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  setToday(picker) {
    picker.close();
    const today = moment().format(Dates.formats.serverDate);
    this.writeValue(today);
    this.onSelectData.emit(today);
  }
}
