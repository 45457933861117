import { Directive } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';

export type TFormEventType = 'pre-submit';

@Directive({
  selector: '[appFormObservable]',
})
export class FormObservableDirective {
  eventBus$ = new Subject<TFormEventType>();

  constructor(private fgd: FormGroupDirective) {}

  preSubmit() {
    if (!this.fgd.form.valid) {
      this.fgd.form.markAsDirty();
      this.fgd.form.markAsTouched();
      this.validateAllFormFields(this.fgd.form);
    }

    this.eventBus$.next('pre-submit');
  }

  private validateAllFormFields(formGroup: FormGroup, emit: any = false) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl && !control.valid) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity({ emitEvent: emit });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control, true);
      } else if (control instanceof FormArray) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.controls.forEach((inControl: FormGroup) => this.validateAllFormFields(inControl));
      }
    });
  }
}
