import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/ngrx/reducers';
import { AuthState } from '@app/auth/ngrx/reducers';

export const getRegisterCheckState = createSelector(fromFeature.getAuthState, (state: AuthState) => state.registerCheck);

export const getErrors = createSelector(getRegisterCheckState, (state: any) => state.errors);

export const getValid = createSelector(getRegisterCheckState, (state: any) => state.valid);

export const getRegisterState = createSelector(fromFeature.getAuthState, (state: AuthState) => state.register);

export const getCompleted = createSelector(getRegisterState, (state: any) => state.completed);
