import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthProvider } from '@app/auth/services/auth.provider';
import { AuthService } from '@app/auth/services/auth.service';
import { authActions } from '@app/auth/ngrx/actions';

@Injectable()
export class AuthEffect {
  constructor(private actions$: Actions, private authProvider: AuthProvider, private authService: AuthService) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.login.LOGIN),
      switchMap(({ payload }) =>
        this.authProvider.login(payload).pipe(
          switchMap(user => of(new authActions.login.LoginCompletedAction(user))),
          catchError(error => of(new authActions.login.LoginFailedAction(error))),
        ),
      ),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.logout.LOGOUT),
      switchMap(() =>
        this.authProvider.logout().pipe(
          switchMap(user => of(new authActions.logout.LogoutCompletedAction(user))),
          catchError(error => of(new authActions.logout.LogoutFailedAction(error))),
        ),
      ),
    );
  });

  changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.changePassword.CHANGE_PASSWORD),
      switchMap(({ payload }) =>
        this.authProvider.changePassword(payload).pipe(
          switchMap(() => of(new authActions.changePassword.ChangePasswordCompletedAction())),
          catchError(error => of(new authActions.changePassword.ChangePasswordFailedAction(error))),
        ),
      ),
    );
  });

  getCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.getCurrentUser.GET_CURRENT_USER),
      switchMap(() =>
        this.authProvider.getCurrentUser().pipe(
          switchMap(user => of(new authActions.getCurrentUser.GetCurrentUserCompletedAction(user))),
          catchError(error => of(new authActions.getCurrentUser.GetCurrentUserFailedAction(error))),
        ),
      ),
    );
  });

  checkRegister$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.checkRegister.CHECK_REGISTER),
      switchMap(({ payload }) =>
        this.authProvider.registerCheck(payload).pipe(
          switchMap(() => of(new authActions.checkRegister.CheckRegisterCompletedAction())),
          catchError(error => of(new authActions.checkRegister.CheckRegisterFailedAction(error))),
        ),
      ),
    );
  });

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.register.REGISTER),
      switchMap(({ payload }) =>
        this.authProvider.register(payload).pipe(
          switchMap(() => of(new authActions.register.RegisterCompletedAction())),
          catchError(error => of(new authActions.register.RegisterFailedAction(error))),
        ),
      ),
    );
  });

  activate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.activate.ACTIVATE),
      switchMap(({ payload }) =>
        this.authProvider.activate(payload).pipe(
          switchMap(() => of(new authActions.activate.ActivateCompletedAction())),
          catchError(error => of(new authActions.activate.ActivateFailedAction(error))),
        ),
      ),
    );
  });

  requestToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.requestToken.REQUEST_TOKEN),
      switchMap(({ payload }) =>
        this.authProvider.requestAuthToken(payload).pipe(
          switchMap(() => of(new authActions.requestToken.RequestTokenCompletedAction())),
          catchError(error => of(new authActions.requestToken.RequestTokenFailedAction(error))),
        ),
      ),
    );
  });

  activateCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.activate.ACTIVATE_COMPLETED),
      switchMap(() => of(new authActions.getCurrentUser.GetCurrentUserAction())),
    );
  });

  validateResetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.validateResetPassword.VALIDATE_RESET_PASSWORD),
      switchMap(({ payload }) =>
        this.authProvider.validateResetPassword(payload).pipe(
          switchMap(() => of(new authActions.validateResetPassword.ValidateResetPasswordCompletedAction())),
          catchError(error => of(new authActions.validateResetPassword.ValidateResetPasswordFailedAction(error))),
        ),
      ),
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.login.LOGIN_COMPLETED),
        switchMap(({ payload }) => this.authService.postLogin(payload)),
      );
    },
    { dispatch: false },
  );

  reset$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.login.LOGIN_RESET),
        switchMap(() => this.authService.postLogout()),
      );
    },
    { dispatch: false },
  );

  logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.logout.LOGOUT_COMPLETED),
        switchMap(() => this.authService.postLogout()),
      );
    },
    { dispatch: false },
  );
}
