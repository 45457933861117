import { NgModule } from '@angular/core';
import { ToastsNgrxModule } from '@app/shared/modules/toasts/ngrx/toasts-ngrx.module';
import { ToastService } from '@app/shared/modules/toasts/services/toast.service';
import { ToastComponent } from '@app/shared/modules/toasts/components/toast/toast.component';
import { ToastNgrxService } from '@app/shared/modules/toasts/services/toast-ngrx.service';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardModule } from "@app/dashboard/dashboard.module";

@NgModule({
  declarations: [ToastComponent],
  providers: [ToastService, ToastNgrxService],
  imports: [ToastsNgrxModule, SharedModule, DashboardModule],
})
export class ToastsModule {
  //
}
