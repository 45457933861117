import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { accountReducer } from '@app/account/ngrx/reducers/account.reducer';
import { accountNgrxModuleName } from '@app/account/ngrx/account-ngrx.const';

export interface AccountState {
  account: any;
}

export const reducers: ActionReducerMap<AccountState> = {
  account: accountReducer,
};

export const getAccountState = createFeatureSelector<AccountState>(accountNgrxModuleName);
