import { Component } from '@angular/core';
import { ProfileModalComponent } from '@app/account/modals/profile/profile.modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { filter, first, pluck } from 'rxjs/operators';
import { AccountNgrxService } from '@app/account/services/account-ngrx.service';
import { UserNgrxService } from '@app/users/services/user-ngrx.service';

@Component({
  selector: 'profile-btn',
  templateUrl: './profile-btn.component.html',
  styleUrls: ['./profile-btn.component.scss'],
})
export class ProfileBtnComponent {
  userName$ = this.authNgrxService.user$.pipe(pluck('username'));
  isImpersonated$ = this.authNgrxService.isImpersonated$;
  impersonatorId$ = this.authNgrxService.adInfo$.pipe(pluck('impersonator_user_id'));

  constructor(
    public dialog: MatDialog,
    private authNgrxService: AuthNgrxService,
    private accountNgrxService: AccountNgrxService,
    private userNgrxService: UserNgrxService,
  ) {
    //
  }

  dispatchProfile() {
    this.authNgrxService.user$.pipe(pluck('id'), first()).subscribe(id => {
      this.accountNgrxService.dispatchGetAccountInfo({ id });
    });
    this.accountNgrxService.info$
      .pipe(
        filter(data => !!data),
        first(),
      )
      .subscribe(data => this.showProfile(data));
  }

  showProfile(data) {
    const dialogRef = this.dialog.open(ProfileModalComponent, { data });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.passwords) {
        this.authNgrxService.dispatchChangePassword(result.passwords);
      }

      if (result?.info) {
        this.authNgrxService.user$.pipe(pluck('id'), first()).subscribe(id => {
          this.accountNgrxService.dispatchSetAccountInfo({ id, info: { ...data, ...result.info } });
        });
      }
    });
  }

  logout() {
    this.authNgrxService.dispatchLogout();
  }

  stopImpersonating() {
    this.impersonatorId$.pipe(first()).subscribe(id => {
      this.userNgrxService.dispatchOffImpersonate(id);
    });
  }
}
