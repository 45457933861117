import { ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateSnapshot } from '@angular/router';

export interface CoreState {
  routerReducer: fromRouter.RouterReducerState<RouterStateSnapshot>;
}

export const reducers: ActionReducerMap<CoreState> = {
  routerReducer: fromRouter.routerReducer,
};
