import { NgModule } from '@angular/core';
import { TermsModalComponent } from '@app/shared/modules/terms/modals/terms-modal/terms.modal.component';
import { TermsService } from '@app/shared/modules/terms/services/terms.service';
import { TermsProvider } from '@app/shared/modules/terms/services/terms.provider';
import { SharedModule } from '@app/shared/shared.module';
import { TermsNgrxModule } from '@app/shared/modules/terms/ngrx/terms-ngrx.module';
import { TermsNgrxService } from '@app/shared/modules/terms/services/terms-ngrx.service';

@NgModule({
  declarations: [TermsModalComponent],
  providers: [TermsService, TermsProvider, TermsNgrxService],
  imports: [SharedModule, TermsNgrxModule],
})
export class TermsModule {
  //
}
