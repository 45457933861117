<form [formGroup]="codeForm" (ngSubmit)="onSubmit()">
	<div class="form-group">
		<div class="input-group">
			<div class="icon">
				<mat-icon>check</mat-icon>
			</div>
			<input placeholder="{{ 'pages.login.form.pass_code' | translate }}"
			       tabindex="4"
			       appWithErrorView
			       type="text"
			       formControlName="passCode"
			       class="form-control">
		</div>
	</div>
	<div class="form-group">
		<button mat-raised-button class="btn btn-primary" type="submit" [class.hidden]="!codeForm.value.passCode" [disabled]="!codeForm.valid">
			{{ 'pages.login.form.verify' | translate }}
		</button>
		<button mat-raised-button class="btn btn-primary" type="submit" [class.hidden]="codeForm.value.passCode" [disabled]="!!(isCodeResentDebounce$ | async)">
			{{ 'pages.login.form.resend' | translate }}
			<mat-spinner *ngIf="authNgrxService.loginPending$ | async" diameter="30"></mat-spinner>
			<span class="counter" *ngIf="!(authNgrxService.loginPending$ | async) && (isCodeResentDebounce$ | async) as isCodeResentDebounce">({{ isCodeResentDebounce }})</span>
		</button>
	</div>
</form>
