import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FleshService } from '@app/layout/services/flesh.service';
import { Flesh } from '@app/core/types/flesh';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { Errors } from '@app/core/types/errors';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private routeChangeStart$ = this.router.events.pipe(filter(event => event instanceof NavigationStart));
  private routeChangeEnd$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

  constructor(
    private router: Router,
    private fleshService: FleshService,
    private authNgrxService: AuthNgrxService,
    public dialog: MatDialog,
  ) {
    this.routeChangeStart$.subscribe(() => fleshService.dispatchClear());
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => this.handleError(err)));
  }

  handleError(error) {
    let errorMessage = '';
    let isGeneralError = true;
    const isAnyDialogOpen = !!this.dialog.openDialogs.length;

    if ('string' === typeof error.error) {
      errorMessage = JSON.parse(error.error).message;
      isGeneralError = !!JSON.parse(error.error)?.errors.filter(err => err.location === Errors.EType.GENERAL).length;
    }
    if ('object' === typeof error.error) {
      errorMessage = error.error.message;
      isGeneralError = !!error.error?.errors.filter(err => err.location === Errors.EType.GENERAL).length;
    }

    errorMessage = errorMessage || error.statusText;

    switch (true) {
      case [400, 404].includes(error.status) && isGeneralError && !isAnyDialogOpen: {
        this.fleshService.dispatch({ message: errorMessage, type: Flesh.EFleshTypes.error });
        break;
      }
      case [401, 403].includes(error.status): {
        this.fleshService.dispatch({ message: errorMessage, type: Flesh.EFleshTypes.error });
        this.authNgrxService.dispatchReset();
        break;
      }
      case [500].includes(error.status):
        break;
      default:
        break;
    }

    return throwError(error);
  }
}
