import { Injectable } from '@angular/core';

import environment from '@env';
import { UrlConst } from '@app/core/constants/url.const';

export interface IUrlParams {
  [key: string]: number | string;
}

@Injectable()
export class URLService {
  protected readonly URL_LIST = {
    [UrlConst.LOGIN]: '/login',
    [UrlConst.LOGOUT]: '/logout',
    [UrlConst.REGISTER]: '/auth/register',
    [UrlConst.TERMS]: '/auth/terms',
    [UrlConst.TERMS_AGREE]: '/auth/terms-agreement',
    [UrlConst.AUTH_TOKEN]: '/auth/tokens/activation',
    [UrlConst.RESET_PASSWORD_REQUEST]: '/auth/tokens/reset',
    [UrlConst.RESET_PASSWORD]: '/auth/reset',
    [UrlConst.CHANGE_PASSWORD]: '/auth/change/password',
    [UrlConst.CURRENT]: '/auth/user/current',

    [UrlConst.ACTIVATE]: '/account/activate/%token',
    [UrlConst.VALIDATE_RESET_PASSWORD]: '/reset-password/%token',

    [UrlConst.USER]: '/user',
    [UrlConst.USER_ONE]: '/user/%id',
    [UrlConst.IMPERSONATE]: '/impersonate/%id',

    [UrlConst.STORE]: '/store',
    [UrlConst.STORE_ONE]: '/store/%id',
    [UrlConst.STORE_POS]: '/store/%id/pos',
    [UrlConst.DASHBOARD]: '/store/%id/metrics',
    [UrlConst.SCHEMA]: '/schema',
  };

  /**
   * Generate Url by key with dynamic params
   */
  public generate(key: string, params: IUrlParams = {}, notApi?): string {
    if (!this.URL_LIST.hasOwnProperty(key)) {
      throw new Error(`URL was not found by key ${key}`);
    }

    let path = this.URL_LIST[key];
    Object.keys(params).forEach((paramKey: string) => {
      path = path.replace(`%${paramKey}`, `${params[paramKey]}`);
    });

    return notApi ? `${environment.api.url}${path}` : `${environment.api.url}${environment.api.prefix}${path}`;
  }
}
