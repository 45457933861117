import { Injectable } from '@angular/core';
import * as fromSelectors from '@app/auth/ngrx/selectors/auth.selector';
import * as fromRegisterSelectors from '@app/auth/ngrx/selectors/register.selector';
import * as fromActivationSelectors from '@app/auth/ngrx/selectors/activation.selector';
import { select, Store } from '@ngrx/store';
import { AuthState } from '@app/auth/ngrx/reducers';
import { authActions } from '@app/auth/ngrx/actions';
import { map, pluck } from 'rxjs/operators';

@Injectable()
export class AuthNgrxService {
  public loginPending$ = this.store.pipe(select(fromSelectors.getPending));
  public user$ = this.store.pipe(select(fromSelectors.getCurrentUser));
  public adInfo$ = this.store.pipe(select(fromSelectors.getAdditionalInfo));
  public isImpersonated$ = this.store.pipe(select(fromSelectors.getImpersonated));
  public mfaInfo$ = this.store.pipe(select(fromSelectors.getMfaInfo));
  public errors$ = this.store.pipe(select(fromRegisterSelectors.getErrors));
  public isValid$ = this.store.pipe(select(fromRegisterSelectors.getValid));
  public isCompleted$ = this.store.pipe(select(fromRegisterSelectors.getCompleted));
  public isActivationCompleted$ = this.store.pipe(select(fromActivationSelectors.getCompleted));
  public activationErrors$ = this.store.pipe(select(fromActivationSelectors.getErrors));
  public userStore$ = this.user$.pipe(map(user => user?.preferred_store));

  public isAdmin$ = this.user$.pipe(pluck('isAdmin'));
  public isSupport$ = this.user$.pipe(pluck('isSupport'));

  constructor(private store: Store<AuthState>) {}

  dispatchLogin(payload) {
    this.store.dispatch(new authActions.login.LoginAction(payload));
  }

  dispatchSetPhone(payload) {
    this.store.dispatch(new authActions.setPhone.SetPhoneAction(payload));
  }

  dispatchVerifyPassCode(payload) {
    this.store.dispatch(new authActions.verifyPasscode.VerifyPasscodeAction(payload));
  }

  dispatchLogout() {
    this.store.dispatch(new authActions.logout.LogoutAction());
  }

  dispatchChangePassword(payload) {
    this.store.dispatch(new authActions.changePassword.ChangePasswordAction(payload));
  }

  dispatchGetCurrentUser() {
    this.store.dispatch(new authActions.getCurrentUser.GetCurrentUserAction());
  }

  dispatchReset() {
    this.store.dispatch(new authActions.login.LoginResetAction());
  }

  dispatchRegister(payload) {
    this.store.dispatch(new authActions.register.RegisterAction(payload));
  }

  dispatchCheckRegister(payload) {
    this.store.dispatch(new authActions.checkRegister.CheckRegisterAction(payload));
  }

  dispatchRegisterReset() {
    this.store.dispatch(new authActions.register.RegisterResetAction());
  }

  dispatchActivate(payload) {
    this.store.dispatch(new authActions.activate.ActivateAction(payload));
  }

  dispatchRequestToken(payload) {
    this.store.dispatch(new authActions.requestToken.RequestTokenAction(payload));
  }

  dispatchResetRequestToken() {
    this.store.dispatch(new authActions.requestToken.RequestTokenResetAction());
  }

  dispatchValidateResetPassword(token) {
    this.store.dispatch(new authActions.validateResetPassword.ValidateResetPasswordAction(token));
  }
}
