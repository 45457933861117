<mat-toolbar class="mat-primary" [class.is-authorized]="isAuthorized$ | async">
	<button *ngIf="isAuthorized$ | async" class="burger" mat-icon-button (click)="onBurgerClicked()">
		<mat-icon>menu</mat-icon>
	</button>
	<a href="/">
		<img class="logo-icon" src="assets/images/ocp-logo.png">
	</a>

	<span class="toolbar-spacer"></span>

	<ng-container *ngIf="isAuthorized$ | async">
		<profile-btn></profile-btn>
	</ng-container>
	<ng-container *ngIf="!(isAuthorized$ | async)">
		<button *ngIf="isLoginShowed$ | async" mat-raised-button class="btn btn-success" routerLink="/auth/login">
			Login
			<mat-icon>login</mat-icon>
		</button>
		<button *ngIf="isRegistrationShowed$ | async" mat-raised-button class="btn btn-default" routerLink="/auth/register">
			Register
		</button>
	</ng-container>
  <div class="version">{{ "" || version }}</div>
</mat-toolbar>
