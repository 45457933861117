import { IActions, setAction } from '@app/core/store/actions.generator';
import { dashboardNgrxModuleName } from '@app/dashboard/ngrx/dashboard-ngrx.const';

export const dashboardActions: {
  getGraphs: IActions;
  getGraph: IActions;
  updateFilters: IActions;
} = {
  getGraphs: setAction(dashboardNgrxModuleName, ['Get', 'Graphs']),
  getGraph: setAction(dashboardNgrxModuleName, ['Get', 'Graph']),
  updateFilters: setAction(dashboardNgrxModuleName, ['Update', 'Filters']),
};
