import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormServerErrorsService } from '@app/shared/modules/forms/services/form-server-errors.service';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SelectComponent } from './components/select/select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,

    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  providers: [FormServerErrorsService, MatDatepickerModule],
  declarations: [DatepickerComponent, SelectComponent],
  exports: [DatepickerComponent, SelectComponent, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatSelectModule],
})
export class AppFormsModule {}
