import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthState } from '@app/auth/ngrx/reducers';
import * as fromSelectors from '@app/account/ngrx/selectors/account.selector';
import { accountActions } from '@app/account/ngrx/actions';

@Injectable()
export class AccountNgrxService {
  public info$ = this.store.pipe(select(fromSelectors.getAccountInfo));

  constructor(private store: Store<AuthState>) {
    //
  }

  dispatchGetAccountInfo(payload) {
    this.store.dispatch(new accountActions.getAccount.GetAccountAction(payload));
  }

  dispatchSetAccountInfo(payload) {
    this.store.dispatch(new accountActions.updateAccount.UpdateAccountAction(payload));
  }

  dispatchSetSchema(payload) {
    this.store.dispatch(new accountActions.setSchema.SetSchemaAction(payload));
  }

  dispatchGetSchema() {
    this.store.dispatch(new accountActions.getSchema.GetSchemaAction());
  }
}
