import { IActions, setAction } from '@app/core/store/actions.generator';
import { accountNgrxModuleName } from '@app/account/ngrx/account-ngrx.const';

export const accountActions: {
  getAccount: IActions;
  updateAccount: IActions;
  setSchema: IActions;
  getSchema: IActions;
} = {
  getAccount: setAction(accountNgrxModuleName, ['Get', 'Account']),
  updateAccount: setAction(accountNgrxModuleName, ['Update', 'Account']),
  setSchema: setAction(accountNgrxModuleName, ['Set', 'Schema']),
  getSchema: setAction(accountNgrxModuleName, ['Get', 'Schema']),
};
