import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { Auth } from '@app/core/types/auth';
import { authActions } from '@app/auth/ngrx/actions';
import { usersActions } from '@app/users/ngrx/actions';

export const defaultState: Auth.IAuthStoreState = {
  loading: false,
  additionalInfo: null,
  currentUser: null,
  impersonated: null,
  error: null,
};

export function sessionReducer(state: Auth.IAuthStoreState = defaultState, action: Action) {
  switch (action.type) {
    case authActions.login.LOGIN_RESET: {
      return update(state, {
        impersonated: { $set: false },
        currentUser: { $set: null },
        additionalInfo: { $set: null },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case authActions.login.LOGIN: {
      return update(state, {
        impersonated: { $set: false },
        currentUser: { $set: null },
        additionalInfo: { $set: null },
        loading: { $set: true },
        error: { $set: null },
      });
    }

    case authActions.getCurrentUser.GET_CURRENT_USER_COMPLETED:
    case authActions.login.LOGIN_COMPLETED: {
      const { user, impersonator_user_id } = (action as any).payload;
      return update(state, {
        currentUser: { $set: user },
        additionalInfo: { $set: { impersonator_user_id } },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case authActions.login.LOGIN_FAILED: {
      const { error: { errors } } = (action as any).payload;
      return update(state, {
        currentUser: { $set: null },
        additionalInfo: { $set: null },
        loading: { $set: false },
        error: { $set: errors },
      });
    }

    case authActions.logout.LOGOUT_COMPLETED: {
      return update(state, {
        impersonated: { $set: false },
        currentUser: { $set: null },
        additionalInfo: { $set: null },
        loading: { $set: false },
        error: { $set: null },
      });
    }

    case usersActions.impersonate.IMPERSONATE: {
      return update(state, {
        impersonated: { $set: false },
        loading: { $set: true },
      });
    }

    case usersActions.impersonate.IMPERSONATE_COMPLETED: {
      const { user, impersonator_user_id } = (action as any).payload;
      return update(state, {
        currentUser: { $set: user },
        additionalInfo: { $set: { impersonator_user_id } },
        impersonated: { $set: true },
        loading: { $set: false },
      });
    }

    case usersActions.impersonate.IMPERSONATE_FAILED: {
      return update(state, {
        impersonated: { $set: false },
        loading: { $set: false },
      });
    }

    case usersActions.offImpersonate.OFF_IMPERSONATE: {
      return update(state, {
        impersonated: { $set: true },
        loading: { $set: true },
      });
    }

    case usersActions.offImpersonate.OFF_IMPERSONATE_COMPLETED: {
      const { user, impersonator_user_id } = (action as any).payload;
      return update(state, {
        currentUser: { $set: user },
        additionalInfo: { $set: { impersonator_user_id } },
        impersonated: { $set: false },
        loading: { $set: false },
      });
    }

    case usersActions.offImpersonate.OFF_IMPERSONATE_FAILED: {
      return update(state, {
        impersonated: { $set: true },
        loading: { $set: false },
      });
    }

    default:
      return state;
  }
}
