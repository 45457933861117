import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from '@app/shared/modules/terms/ngrx/reducers';
import { effects } from '@app/shared/modules/terms/ngrx/effects';
import { termsNgrxModuleName } from '@app/shared/modules/terms/ngrx/terms-ngrx.const';

@NgModule({
  imports: [StoreModule.forFeature(termsNgrxModuleName, reducers), EffectsModule.forFeature(effects)],
})
export class TermsNgrxModule {
  //
}
