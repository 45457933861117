<div class="additional-links">
	<ng-container [ngSwitch]="place">
		<ng-container *ngSwitchCase="'login'">
			<ng-container *ngTemplateOutlet="forgot"></ng-container>
			<ng-container *ngTemplateOutlet="or"></ng-container>
			<ng-container *ngTemplateOutlet="register"></ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'activate'">
			<ng-container *ngTemplateOutlet="login"></ng-container>
			<ng-container *ngTemplateOutlet="or"></ng-container>
			<ng-container *ngTemplateOutlet="register"></ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'reset-request'">
			<ng-container *ngTemplateOutlet="login"></ng-container>
			<ng-container *ngTemplateOutlet="or"></ng-container>
			<ng-container *ngTemplateOutlet="register"></ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'reset'">
			<ng-container *ngTemplateOutlet="or"></ng-container>
			<ng-container *ngTemplateOutlet="reset_request"></ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'register'">
			<ng-container *ngTemplateOutlet="already_have_account"></ng-container>
		</ng-container>
	</ng-container>
</div>


<ng-template #or>
	<p class="line-thru">
		{{ 'pages.auth.components.auth-footer.or' | translate }}
	</p>
</ng-template>

<ng-template #register>
	<p>
		{{ 'pages.auth.components.auth-footer.dont_have_an_account' | translate }}
		<a [routerLink]="links.register">{{ 'pages.auth.components.auth-footer.register' | translate }}</a>
	</p>
</ng-template>

<ng-template #login>
	<p class="forgot">
		<a [routerLink]="links.login">{{ 'pages.auth.components.auth-footer.ready_to_login' | translate }}</a>
	</p>
</ng-template>

<ng-template #forgot>
	<p class="forgot">
		<a [routerLink]="links.reset">{{ 'pages.auth.components.auth-footer.forgot_password' | translate }}</a>
	</p>
</ng-template>

<ng-template #already_have_account>
	<p>
		{{ 'pages.auth.components.auth-footer.already_account' | translate }}
		<a [routerLink]="links.login">{{ 'pages.auth.components.auth-footer.login_now' | translate }}</a>
	</p>
</ng-template>

<ng-template #reset_request>
	<p>
		<a [routerLink]="links.reset">{{ 'pages.auth.components.auth-footer.request_reset' | translate }}</a>
	</p>
</ng-template>
