import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BarGraphComponent } from '@app/shared/modules/graphs/components/bar-graph/bar-graph.component';
import { DoughnutGraphComponent } from '@app/shared/modules/graphs/components/doughnut-graph/doughnut-graph.component';
import { LineGraphComponent } from '@app/shared/modules/graphs/components/line-graph/line-graph.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [BarGraphComponent, LineGraphComponent, DoughnutGraphComponent],
  providers: [],
  exports: [BarGraphComponent, LineGraphComponent, DoughnutGraphComponent],
})
export class GraphsModule {
  //
}
