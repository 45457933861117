import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormServerErrorsService {
  applyErrors(form: FormGroup, errors) {
    errors.forEach(error => {
      const ctrl = form.get(error.name);

      if (ctrl) {
        ctrl.setErrors({ server_error: error.description });
      }
    });
  }
}
