import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'login-phone-form',
  templateUrl: './login-phone-form.component.html',
  styleUrls: ['./login-phone-form.component.scss'],
})
export class LoginPhoneFormComponent implements OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject();
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  public phoneForm = new FormGroup({
    phone: new FormControl(null, [Validators.required]),
  });

  constructor(public authNgrxService: AuthNgrxService) {
    this.authNgrxService.loginPending$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(loginPending => {
      loginPending ? this.phoneForm.disable() : this.phoneForm.enable();
    });
  }

  onSubmit() {
    if (!this.phoneForm.valid) {
      return;
    }

    this.submitted.emit(this.phoneForm.value);
    this.phoneForm.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
