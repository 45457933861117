export namespace Errors {
  export enum EType {
    BODY = 'body',
    GENERAL = 'general',
    SERVER_ERROR = 'server-error',
    HEADER = 'header',
    PATH = 'path',
  }
  export interface IError {
    name: string;
    location: string;
    description: string;
  }
}
