import { Injectable } from '@angular/core';
import { Dashboard } from '@app/core/types/dashboard';
import { dashboardActions } from '@app/dashboard/ngrx/actions';
import { DashboardState } from '@app/dashboard/ngrx/reducers';
import * as fromSelectors from '@app/dashboard/ngrx/selectors/dashboard.selector';
import { select, Store } from '@ngrx/store';

@Injectable()
export class DashboardNgrxService {
  public dashboardData$ = this.store.pipe(select(fromSelectors.getDashboard));
  public getFilters$ = this.store.pipe(select(fromSelectors.getFilters));

  constructor(private store: Store<DashboardState>) {}

  dispatchGetGraphs({ id, params }: Dashboard.IRequest) {
    this.store.dispatch(new dashboardActions.getGraphs.GetGraphsAction({ id, params }));
  }

  dispatchGetGraph({ id, params }: Dashboard.IRequest) {
    this.store.dispatch(new dashboardActions.getGraph.GetGraphAction({ id, params }));
  }

  dispatchFilters(filters) {
    this.store.dispatch(new dashboardActions.updateFilters.UpdateFiltersAction(filters));
  }
}
