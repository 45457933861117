import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { validationCompose } from '@app/core/validation/validation.compose';
import { filter, first } from 'rxjs/operators';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { FormServerErrorsService } from '@app/shared/modules/forms/services/form-server-errors.service';

@Component({
  templateUrl: './activate.page.component.html',
  styleUrls: ['./activate.page.component.scss'],
})
export class ActivatePageComponent implements OnInit {
  activateForm = new FormGroup({
    email: new FormControl('', [validationCompose.email.rules]),
  });

  constructor(
    public authNgrxService: AuthNgrxService,
    public route: ActivatedRoute,
    public authService: AuthService,
    public formServerErrorsService: FormServerErrorsService,
  ) {}

  ngOnInit() {
    this.authNgrxService.dispatchResetRequestToken();
    this.route.params.subscribe(params => this.authNgrxService.dispatchActivate(params));

    this.authNgrxService.user$
      .pipe(
        filter(user => !!user),
        first(),
      )
      .subscribe(user => this.authService.postLogin(user));
  }

  onSubmit() {
    if (!this.activateForm.valid) {
      return;
    }

    this.authNgrxService.dispatchRequestToken(this.activateForm.value);
    this.authNgrxService.activationErrors$
      .pipe(
        filter(errors => !!errors),
        first(),
      )
      .subscribe(errors => this.formServerErrorsService.applyErrors(this.activateForm, errors));
  }
}
