import {
  Directive,
  OnDestroy,
  OnInit,
  Self,
  Optional,
  ComponentRef,
  ElementRef,
  InjectionToken,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, merge, Observable, EMPTY } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { ErrorFeedbackComponent } from '@app/core/validation/components/error-feedback/error-feedback.component';
import { FormObservableDirective } from '@app/core/validation/directives/form-observable';
import { ValidationService } from '@app/core/validation/validation.service';

export type TErrrorView = 'tooltip' | 'element' | 'portal';

export interface IFormErrorViewConfig {
  tooltip_position: ConnectedPosition;
}

export const APP_FORM_ERROR_VIEW_CONFIG: IFormErrorViewConfig = {
  tooltip_position: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
};

export const APP_FORM_ERROR_VIEW: InjectionToken<IFormErrorViewConfig> = new InjectionToken<IFormErrorViewConfig>(
  'app-form-error-view-config',
);

@Directive({
  selector: '[appWithErrorView]',
})
export class ErrorViewDirective implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject();
  private overlayRef: OverlayRef;
  private componentRef: ComponentRef<ErrorFeedbackComponent>;
  private eventBus$: Observable<any>;
  public elementRef: ElementRef;
  public viewContainerRef: ViewContainerRef;

  @Input('appWithErrorView') name: string; // required for Custom Control and Single FormControl
  @Input() appErrorViewPortalHost: CdkPortalOutlet; // required for Portal
  @Input() appErrorViewType: TErrrorView = 'element';

  constructor(
    private elr: ElementRef,
    private vcr: ViewContainerRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private validationService: ValidationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Self() private control: NgControl,
    // @Host() @Optional() private errorSlot: ErrorViewSlotDirective,
    @Optional() private form: FormObservableDirective, // Could be using together with Submit Event
    @Inject(APP_FORM_ERROR_VIEW) public viewConfig: IFormErrorViewConfig,
  ) {}

  ngOnInit() {
    // @todo: Investigate how better to get child directive access -> ErrorViewSlotDirective
    // this.elementRef = this.errorSlot ? this.errorSlot.elr : this.elr;
    // this.viewContainerRef = this.errorSlot ? this.errorSlot.vcr : this.vcr;
    this.elementRef = this.elr;
    this.viewContainerRef = this.vcr;
    this.eventBus$ = this.form ? this.form.eventBus$ : EMPTY;

    if (this.name) {
      this.control.name = this.name;
    }

    merge(this.eventBus$, this.control.valueChanges, this.control.statusChanges.pipe(distinctUntilChanged()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.hide();
        if (this.control.errors) {
          this.show();
        }
      });
  }

  attachComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ErrorFeedbackComponent);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
  }

  attachPortal() {
    if (!this.appErrorViewPortalHost) {
      return;
    }
    const portalComponent = new ComponentPortal(ErrorFeedbackComponent);
    this.componentRef = this.appErrorViewPortalHost.attachComponentPortal(portalComponent);
  }

  attachTooltip() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.viewConfig.tooltip_position]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    const tooltipPortal = new ComponentPortal(ErrorFeedbackComponent, this.viewContainerRef);
    this.componentRef = this.overlayRef.attach(tooltipPortal);
  }

  prepareComponentRef() {
    // debugger;
    switch (this.appErrorViewType) {
      case 'element':
        this.attachComponent();
        break;
      case 'tooltip':
        this.attachTooltip();
        break;
      case 'portal':
        this.attachPortal();
        break;
      default:
        break;
    }
  }

  show() {
    const errorParams = this.validationService.getParams(this.control);
    if (this.control && errorParams) {
      this.prepareComponentRef();
      if (this.componentRef) {
        this.componentRef.instance.appFormControl = this.control;
        this.componentRef.instance.appErrorParams = errorParams;
      }
    }
  }

  hide() {
    if (this.appErrorViewPortalHost && this.appErrorViewPortalHost.hasAttached) {
      this.appErrorViewPortalHost.detach();
    }
    if (this.overlayRef && this.overlayRef.hasAttached) {
      this.overlayRef.detach();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.hide();
  }
}
