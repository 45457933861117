<h2>{{ 'pages.login.title' | translate }}</h2>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
	<div class="form-group">
		<div class="input-group">
			<div class="icon">
				<mat-icon>mail</mat-icon>
			</div>
			<input placeholder="{{ 'pages.login.form.email' | translate }}"
			       tabindex="1"
			       appWithErrorView
			       type="text"
			       autocomplete="username"
			       formControlName="username"
			       class="form-control">
		</div>
	</div>
	<div class="form-group">
		<div class="input-group">
			<div class="icon">
				<mat-icon>lock</mat-icon>
			</div>
			<input placeholder="{{ 'pages.login.form.password' | translate }}"
			       tabindex="2"
			       appWithErrorView
			       type="password"
			       autocomplete="current-password"
			       formControlName="password"
			       class="form-control">
		</div>
	</div>
	<div class="form-group">
		<button mat-raised-button class="btn btn-primary" type="submit"  [disabled]="!loginForm.valid">
			{{ 'pages.login.form.login' | translate }}
		</button>
	</div>
</form>

<auth-footer place="login"></auth-footer>
