import { Injectable } from '@angular/core';
import { storesActions } from '@app/stores/ngrx/actions';
import { ToastService } from '@app/shared/modules/toasts/services/toast.service';
import { Toast } from '@app/core/types/toast';
import { authActions } from '@app/auth/ngrx/actions';
import { usersActions } from '@app/users/ngrx/actions';
import { AuthNgrxService } from '@app/auth/services/auth-ngrx.service';
import { filter, first } from 'rxjs/operators';
import { DashboardNgrxService } from "@app/dashboard/services/dashboard-ngrx.service";
import { dashboardActions } from "@app/dashboard/ngrx/actions";

@Injectable()
export class ToastNgrxService {
  user$ = this.authNgrxService.user$.pipe(filter(user => !!user));

  constructor(private toastService: ToastService, private authNgrxService: AuthNgrxService, private dashboardNgrxService: DashboardNgrxService) {
    //
  }

  process(type) {
    switch (type) {
      case dashboardActions.getGraphs.GET_GRAPHS_COMPLETED:
        this.processRefresh('shared.modules.toasts.messages.refresh_completed', Toast.EToastTypes.success)
        break;
      case authActions.activate.ACTIVATE_FAILED:
        this.toastService.process('shared.modules.toasts.messages.wrong_token', Toast.EToastTypes.error);
        break;

      case storesActions.updateStore.UPDATE_STORE_COMPLETED:
        this.toastService.process('shared.modules.toasts.messages.store_updated', Toast.EToastTypes.success);
        break;

      case storesActions.createStore.CREATE_STORE_COMPLETED:
        this.toastService.process('shared.modules.toasts.messages.store_added', Toast.EToastTypes.success);
        break;

      case authActions.register.REGISTER_COMPLETED:
        this.toastService.process('shared.modules.toasts.messages.activation_sent', Toast.EToastTypes.success);
        break;

      case usersActions.getUsers.GET_USERS_COMPLETED:
        this.toastService.process('shared.modules.toasts.messages.user_search_completed', Toast.EToastTypes.success);
        break;

      case 'refresh_completed': // TODO
        this.toastService.process('shared.modules.toasts.messages.refresh_completed', Toast.EToastTypes.success);
        break;

      case authActions.login.LOGIN_COMPLETED:
        this.processWithDeps('shared.modules.toasts.messages.logged_in', Toast.EToastTypes.info, 'username');
        break;

      case authActions.logout.LOGOUT:
        this.processWithDeps('shared.modules.toasts.messages.logged_out', Toast.EToastTypes.info, 'username');
        break;

      case usersActions.impersonate.IMPERSONATE_COMPLETED:
        this.processWithDeps('shared.modules.toasts.messages.impersonating', Toast.EToastTypes.warning, 'username');
        break;

      case usersActions.offImpersonate.OFF_IMPERSONATE_COMPLETED:
        this.toastService.process('shared.modules.toasts.messages.impersonating_stopped', Toast.EToastTypes.warning);
        break;
    }
  }

  processWithDeps(messageId, type, dependency) {
    this.user$.pipe(first()).subscribe(data => {
      this.toastService.process(messageId, type, { [dependency]: data[dependency] });
    });
  }

  processRefresh(messageId, type) {
    this.dashboardNgrxService.getFilters$
      .pipe(first(), filter(filters => filters?.refresh))
      .subscribe(() => this.toastService.process(messageId, type));
  }
}
