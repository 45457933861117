import { Injectable } from '@angular/core';
import { appRoutingNames } from '@app/app-routing-names';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private router: Router) {
    //
  }

  postImpersonate(payload) {
    return from(this.router.navigateByUrl(appRoutingNames.DASHBOARD)).pipe(map(() => payload));
  }

  postOffImpersonate(payload) {
    return from(this.router.navigateByUrl(appRoutingNames.USER)).pipe(map(() => payload));
  }
}
