<mat-grid-tile-header>
    <mat-icon>apps</mat-icon>
    <input class="hide-input" matInput
           [matDatepicker]="picker"
           [formControl]="date"
           [max]="maxDay"
    >
    <div class="title" (click)="picker.open()">TOTALS FOR TODAY ({{date.value | date: 'MM/dd/yyyy'}}</div>
    <mat-icon>calendar_month</mat-icon>)
    <mat-datepicker #picker></mat-datepicker>
</mat-grid-tile-header>