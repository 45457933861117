import { Action } from '@ngrx/store';
import update from 'immutability-helper';
import { usersActions } from '@app/users/ngrx/actions';

export const defaultState: any = {
  filter: null,
};

export function usersFilterReducer(state: any = defaultState, action: Action) {
  switch (action.type) {
    case usersActions.setUsersFilter.SET_USERS_FILTER: {
      return update(state, {
        filter: { $set: (action as any).payload },
      });
    }

    default:
      return state;
  }
}
